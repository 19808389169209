import React, {useState, useEffect, useRef} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {labels} from '../../Constants/Translations';
import {selected_lang} from '../../Utils/env';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import CustomTabs from '../CustomTabs';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {
  createFollowUpMessage,
  fixName,
  getMaxDateFromToday,
  list,
} from '../../Utils/commonUtils';
import AssigneeBuilders from '../SessionList/AssigneeBuilders';
import CropImage from '../Cropper/CropImage';
import CustomModal from '../CustomModal';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {get_my_all_associate_workspaces} from '../../Store/Actions/SessionAction';
import {useDispatch, useSelector} from 'react-redux';
import PersonalCreateModel from '../Modals/PersonalCreateModel';
import commonApi from '../../Services/CommonService';
import WatchesBuilders from '../SessionList/WatchesBuilders';
import LoaderSpinner from '../Modals/LoaderSpinner';
import moment from 'moment/moment';
import ImagePreview from '../Modals/ImagePreview';
import AlertModel from '../Modals/AlertModel';
import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import TimePicker from 'rc-time-picker';
import CustomDropdown from '../CustomDropdown';
import EditPencilSvg from '../../Svg/EditPencilSvg';
import {RoiLogo} from '../../Constants/Images';
import Calender from '../FeedBack/Calender';

const CreateAction = props => {
  const [tabType, setTabType] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [validated, setValidated] = useState(false);
  const [title, setTitle] = useState('');
  const [piorityList, setPiorityList] = useState([
    {title: 'Low', id: 'low'},
    {title: 'Medium', id: 'medium'},
    {title: 'High', id: 'high'},
  ]);
  const [piorityListid, setPiorityListId] = useState(piorityList[0].id);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [discription, setDiscription] = useState('');
  const [showModel, setShowModel] = useState('');
  const [file, setFile] = useState(null);

  const [ticketImg, setTicketImg] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [addTaskValue, setAddTaskValue] = useState('');
  const [sliderImage, setSliderImage] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const [workSpaceIds, setWorkSpaceIds] = useState([]);
  const [assigneIds, setAssigneIds] = useState([]);
  const [assigneItem, setAssigneItem] = useState([]);
  const [watchesIds, setWatchesIds] = useState([]);
  const [watchesItem, setWatchesItem] = useState([]);
  const [originalWatchesItem, setOriginalWatchesItem] = useState([]);
  const [showSelected, setShowSelected] = useState(false);
  const [searchqueryAssighne, setSearchqueryAssighne] = useState('');
  const [searchqueryWatches, setSearchqueryWatches] = useState('');
  const [searchqueryWatchesCheck, setSearchqueryWatchesCheck] = useState(false);
  const [required, setRequired] = useState(false);
  const [checkListTitle, setCheckListTitle] = useState('');
  const [checkListTitleArray, setCheckListTitleArray] = useState([]);
  const [searchqueryAssighneCheck, setSearchqueryAssighneCheck] =
    useState(false);

  const [editingIndex, setEditingIndex] = useState(null);
  const [modelImg, setModelImg] = useState('');
  const [serverTimeS, setServerTime] = useState('');
  const [indexOfImage, setIndexOfImage] = useState('');
  const [taskBoard, setTaskBoard] = useState([]);
  const [taskBoardId, setTaskBoardId] = useState('');
  const filterCreater =
    props.allChatUsers &&
    props.allChatUsers.length > 0 &&
    props.allChatUsers.filter(user => user.type !== 'creator');
  const buttonRef = useRef(null);
  // const [showLoader, setshowLoader] = useState('')
  const [contact, setContact] = useState([]);
  const [addContact, setAddContact] = useState('');
  const [topIcons, setTopIcons] = useState({
    focus: false,
    location: false,
    vaccinate: false,
    dollar: false,
    store: true,
    bullseye: false,
    personal: false,
  });
  const [showComponent, setShowComponent] = useState('');
  const [listOfSearch, setListOfSearch] = useState('');
  const [deletedIds, setDeletedIds] = useState([]);
  const listWorspacerator = useSelector(
    state => state.hitAssociateWorkSpace.data,
  );

  const assigneRef = useRef();
  const watchersRef = useRef();

  const [pickerOpen, setPickerOpen] = useState(false);
  const [highImpact, setHighImpact] = useState(false);
  const personal = useSelector(state => state.hitAssociateWorkSpace.personal);
  const assigneeBuilders = useSelector(state => state.AllBuilderList);
  const watchersBuilders = useSelector(state => state.AllWatchesList);
  const dispatch = useDispatch();

  let maxDate = moment().format('2099-12-30');

  useEffect(() => {
    dispatch(
      get_my_all_associate_workspaces({token: localStorage.getItem('token')}),
    );
    serverTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddOrUpdateItem = () => {
    if (!checkListTitle.trim()) {
      errorMessage('Please add title');
      return;
    }

    if (editingIndex !== null) {
      // Edit mode
      const updatedArray = [...checkListTitleArray];
      const currentItem = checkListTitleArray[editingIndex];

      updatedArray[editingIndex] = currentItem.id
        ? {id: currentItem.id, title: checkListTitle}
        : {title: checkListTitle};

      setCheckListTitleArray(updatedArray);
      setEditingIndex(null);
    } else {
      // Add mode
      setCheckListTitleArray([...checkListTitleArray, {title: checkListTitle}]);
    }

    setCheckListTitle('');
  };
  const handleEditDelete = (item, index, action) => {
    if (action === 'edit') {
      setCheckListTitle(item.title);
      setEditingIndex(index);
    } else if (action === 'delete') {
      const updatedArray = checkListTitleArray.filter((_, i) => i !== index);
      if (item?.id) {
        setDeletedIds([...deletedIds, item.id]);
      }
      setCheckListTitleArray(updatedArray);
    }
  };

  const serverTime = () => {
    // var payLoad = {
    //   search_query: qry,
    //   user_id: localStorage.getItem('user'),
    // };
    commonApi
      .check_server_time()
      .then(res => {
        setServerTime(res.server_time);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_task_boards = () => {
    var payLoad = {
      search_query: '',
    };
    commonApi
      .get_task_boards(payLoad)
      .then(res => {
        if (res.data.length > 0) {
          if (
            props.type &&
            props.type === 'board' &&
            props.boardId &&
            props.boardId !== ''
          ) {
            setTaskBoardId(props.boardId);
          } else {
            setTaskBoardId(res.data[0].id);
          }

          setTaskBoard(res.data);
        } else {
          setAddTask(true);
        }
        if (props.editAction?.board_id) {
          setTaskBoardId(props.editAction?.board_id);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const add_task_board = () => {
    // const trimmedValue = addTaskValue.trim();

    // Check if the input is empty or starts with spaces
    if (addTaskValue === '' || addTaskValue.startsWith(' ')) {
      errorMessage('Please add valid value');
      return false; // Exit the function if the value is empty or has leading spaces
    }
    var payLoad = {
      title: addTaskValue,
    };
    commonApi
      .add_task_board(payLoad)
      .then(res => {
        successMessage(res?.message);
        setAddTaskValue('');
        get_task_boards();
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  // useEffect(() => {
  //   if (tabType == labels.ASSIGNEE[selected_lang]) {
  //     setshowLoader(true);
  //     setTimeout(() => {
  //       setshowLoader(false);
  //     }, 4000);
  //   }
  // }, [tabType]);

  useEffect(() => {
    if (workSpaceIds.length > 0) {
    } else {
      // Filter the workspaces with status "active" from the API response
      const userExist = listWorspacerator.filter(
        workspace => workspace.status === 'active',
      );

      // Extract the 'id' values from 'userExist'
      const updatedIds = userExist.map(workspace => workspace.id);

      if (personal === 'yes') {
        updatedIds.push('personal'); // Add 'personal' to the ids array if needed
      }

      // setActiveWorkspacesId(updatedIds);
      setWorkSpaceIds(updatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listWorspacerator]);

  const handle = event => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      // if (sliderImage.length == 0) {
      //   errorMessage('Please select image');
      // } else
      if (assigneIds.length === 0) {
        errorMessage('Please select Assignee');
      } else if (!taskBoardId) {
        errorMessage('Please add task board');
      } else {
        setshowLoader(true);
        if (props.editAction) {
          EditAction();
        } else {
          createAction();
        }
      }

      setValidated(false);
    }
  };

  useEffect(() => {
    get_task_boards();
    if (props.editAction) {
      setTitle(props.editAction.title);
      setPiorityListId(props.editAction.priority);
      setDiscription(props.editAction.description);
      setHighImpact(props.editAction?.impact_high);
      if (props.editAction?.check_list_details?.length > 0) {
        setCheckListTitleArray(props.editAction.check_list_details);
      }
      setRequired(props.editAction?.required);

      const dueTime = props.editAction.due_time;

      console.log(
        props.editAction.due_time,
        'props.editAction.due_time;props.editAction.due_time;',
      );
      let momentTime = null;
      if (dueTime && dueTime !== '' && dueTime !== '00:00:00') {
        momentTime = moment(dueTime, 'HH:mm:ss');
      }

      console.log(momentTime, 'momentTimemomentTime');
      // setTime(
      //  new Date (props.editAction.due_time !== '' &&
      //     props.editAction.due_time !== '00:00:00'
      //     ? props.editAction.due_time
      //     : ''),
      // );
      setTime(momentTime);
      setDate(
        props.editAction.due_date !== '' &&
          props.editAction.due_time !== '0000-00-00 00:00:00'
          ? moment(props.editAction.due_date).format('YYYY-MM-DD')
          : '',
      );
      // setSliderImage(props.editAction.images.split());
      setSliderImage(
        props.editAction.images !== ''
          ? props.editAction.images.split(',')
          : [],
      );

      if (props.editAction?.workspace_ids) {
        setWorkSpaceIds(props.editAction.workspace_ids.split(','));
      }

      if (props.editAction?.personal === 'yes') {
        setTimeout(() => {
          setWorkSpaceIds(previous => [...previous, 'personal']);
        }, 1500);
      }
      // setTimeout(() => {
      setAssigneItem(
        props.editAction.assigne_detail &&
          typeof props.editAction.assigne_detail === 'object' &&
          Object.entries(props.editAction.assigne_detail).length
          ? [props.editAction.assigne_detail]
          : [],
      );
      // ([props.editAction.assigne_detail]);
      setAssigneIds(
        props.editAction.assigne === '0' ? [] : [props.editAction.assigne],
      );
      // }, 4000);

      if (
        props.editAction.watchers_detail &&
        props.editAction.watchers_detail.length > 0
      ) {
        setWatchesItem(props.editAction.watchers_detail);
        setOriginalWatchesItem(props.editAction.watchers_detail);
        setWatchesIds(props.editAction.watchers.split(','));
      }
    } else {
      if (props.userData || props.type === 'userProfile') {
        setAssigneItem([
          props.userData
            ? props.userData
            : JSON.parse(localStorage.getItem('allUserData')),
        ]);
        // ([props.editAction.assigne_detail]);
        setAssigneIds([
          props.userData && props.userData.id
            ? props.userData.id
            : localStorage.getItem('id'),
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editAction]);

  console.log(assigneIds, assigneItem, 'aaaaaaaaaa');

  useEffect(() => {
    if (props.futureDate) {
      setDate(moment(props.futureDate).format('YYYY-MM-DD'));
    } else {
      if (!props.editAction) {
        setDate('');
      }
    }
    if (props.followUpType === 'follow_up') {
      get_follow_ups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EditAction = () => {
    const mergedDateTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format(
      'YYYY-MM-DD HH:mm:ss',
    );

    const formattedTime = moment(time, 'HH:mm').format('HH:mm');
    // setshowLoader(true);
    var payLoad = {
      check_list: checkListTitleArray,
      required: required,
      delete_list: deletedIds,
      title: title,
      action_id: props.editAction.id,
      priority: piorityListid,
      due_date: mergedDateTime,
      due_time: formattedTime,
      description: discription,
      assigne: assigneIds.toString(','),
      watchers: watchesIds.toString(','),
      workspace_ids: workSpaceIds.toString(','),
      images: sliderImage.toString(','),
      impact_high: highImpact,
      board_id: taskBoardId,
      // association_name: localStorage.getItem('id'),
      association_type: props.editAction.association_type,
      association_id: props.editAction.association_id,
    };

    commonApi
      .create_action(payLoad)
      .then(res => {
        if (res.status === 200) {
          setshowLoader(false);
          props.onClose();
          if (props.type) {
            props.closeActionDeatil();
          }
          props.onCloseHitApi && props.onCloseHitApi();
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const createAction = () => {
    // setshowLoader(true);
    // setshowLoader(true)
    const mergedDateTime = date
      ? moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm').format(
          'YYYY-MM-DD HH:mm:ss',
        )
      : '';

    const formattedTime = time ? moment(time, 'HH:mm').format('HH:mm') : '';

    console.log(formattedTime, 'formattedTime');

    var payLoad = {
      check_list: checkListTitleArray,
      title: title,
      required: required,
      priority: piorityListid,
      due_date: mergedDateTime,
      due_time: formattedTime,
      description: discription,
      assigne: assigneIds.toString(','),
      watchers: watchesIds.toString(','),
      workspace_ids: workSpaceIds.toString(','),
      impact_high: highImpact,
      images: sliderImage.length > 0 ? sliderImage.toString(',') : '',
      board_id: taskBoardId,
      // association_name: localStorage.getItem('id'),
      association_type:
        props.chatType === 'personal'
          ? 'group_chat'
          : props.chatType === 'opportunity'
          ? 'opportunity'
          : props.chatType === 'session'
          ? 'session_chat'
          : props.chatType === 'event'
          ? 'event_chat'
          : props.followUpType === 'follow_up'
          ? props.followUpType
          : 'profile',
      association_id: props.chatType
        ? props.chatId
        : props.userData
        ? props.userData.id
        : localStorage.getItem('id'),
    };

    commonApi
      .create_action(payLoad)
      .then(res => {
        if (res.status === 200) {
          if (props.component && props.component === 'userProfile') {
            props.refreshUserDataList();
          }
          props.onCloseHitApi && props.onCloseHitApi();
          setshowLoader(false);
          props.onClose();
          successMessage(res.message);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_follow_ups = () => {
    // setshowLoader(true);
    var payLoad = {
      user_id: localStorage.getItem('id'),
      search_query: '',
    };
    //
    commonApi
      .followers_list(payLoad)
      .then(res => {
        //

        const filterdata = res.data.filter(
          item => item.id === props.userData.id,
        );

        if (filterdata.length > 0) {
          setAssigneItem([filterdata[0]]);
          setAssigneIds([filterdata[0].id]);
          // console.log(res.data, 'heheeheheeheheeheeh');
          //  if (type) {
          setTitle(createFollowUpMessage(filterdata[0]));
          //  } else {
          // setTitle(createFollowUpMessage(filterdata[0]))
          //  }
        }

        //  setTitle()
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  const get_follow_ups_search = qry => {
    // setshowLoader(true);
    var payLoad = {
      user_id: localStorage.getItem('id'),
      search_query: qry,
    };
    //
    commonApi
      .followers_list(payLoad)
      .then(res => {
        setListOfSearch(res.data);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const changeTicketImg = e => {
    if (e.target.files.length === 0) return false;
    const file = e.target.files[0];

    // let fileExtension = file.name.split('.').pop().toLowerCase();

    // If no image selected, return
    // || fileExtension === "gif"
    if (!/^image\//.test(file.type)) {
      errorMessage(`File is not an image.`);
      return false;
    } else if (allowedImageTypes.includes(file.type)) {
      setFile(file);
      // setCropImg(true);
      setShowModel('crop_modal');

      e.target.value = null;

      // const filePath = e.target.files[0];
      // const objectUrl = URL.createObjectURL(filePath);
      // let reader = new FileReader();
      // reader.readAsDataURL(filePath);
      // reader.onload = e => {
      //   //

      //   uploadMedia(e.target.result);
      // };

      // setTicketImg(objectUrl);
      // //
      // //
    } else {
      errorMessage('The file format is not supported');
      e.target.value = null; // reset the file input
    }
    // const imgTypes = ['image/gif', 'image/jpeg', 'image/png'];
  };

  const CircleUserListData = item => {
    if (item.length > 0) {
      item.forEach((miniItem, index) => {
        if (
          !watchesIds.includes(miniItem.id) &&
          !assigneIds.includes(miniItem.id)
        ) {
          setWatchesIds(previous => [...previous, miniItem.id]);
          setWatchesItem(previous => [...previous, miniItem]);
          setOriginalWatchesItem(previous => [...previous, miniItem]);
        }
      });
    }
  };
  useEffect(() => {
    if (watchesIds.includes(assigneIds.toString(','))) {
      setWatchesIds(previous =>
        previous.filter(miniItem => miniItem !== assigneIds.toString(',')),
      );
      setWatchesItem(previous =>
        previous.filter(miniItem => miniItem.id !== assigneIds.toString(',')),
      );
      setOriginalWatchesItem(previous =>
        previous.filter(miniItem => miniItem.id !== assigneIds.toString(',')),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneIds]);

  const assigneeHandle = (type, item) => {
    if (watchesIds.includes(item.id)) {
      errorMessage('You cannot select this user');
    } else {
      if (!assigneIds.includes(item.id)) {
        setAssigneIds([item.id]);
        setAssigneItem([item]);
      } else {
        // setAssigneIds([item.id]);
        // setAssigneItem([item]);
        setAssigneIds(previous =>
          previous.filter(miniItem => miniItem !== item.id),
        );
        setAssigneItem(previous =>
          previous.filter(miniItem => miniItem.id !== item.id),
        );
      }
    }

    // setAllBuilders(previous => [...previous, item]);
    // setOriginalData(previous => [...previous, item]);
  };
  const watchesHandle = (type, item) => {
    if (assigneIds.includes(item.id)) {
      errorMessage('You cannot select this user');
    } else {
      if (!watchesIds.includes(item.id)) {
        setWatchesIds(previous => [...previous, item.id]);
        setWatchesItem(previous => [...previous, item]);
        setOriginalWatchesItem(previous => [...previous, item]);
        // setAllBuilders(previous => [...previous, item]);
        // setOriginalData(previous => [...previous, item]);
      } else {
        if (watchesIds.includes(item.id)) {
          setWatchesIds(previous =>
            previous.filter(miniItem => miniItem !== item.id),
          );
          setWatchesItem(previous =>
            previous.filter(miniItem => miniItem.id !== item.id),
          );
          setOriginalWatchesItem(previous =>
            previous.filter(miniItem => miniItem.id !== item.id),
          );
        }
      }
    }
  };

  const getUserNames = e => {
    const txt = e.target.value;
    const wordsArray = txt.split(' ');

    const lastWord = wordsArray[wordsArray.length - 1];
    const lastCharacter = txt.slice(-1);

    if (
      lastWord.startsWith('@') &&
      containsSingleAtSymbol(lastWord) &&
      lastCharacter !== '@' &&
      props.followUpType === 'follow_up'
    ) {
      // getFollowers(lastWord.replace('@', ''), 'usersList');
      get_follow_ups_search(lastWord.replace('@', ''));
    } else if (lastCharacter === ' ') {
      setListOfSearch('');
    }
  };

  const containsSingleAtSymbol = inputString => {
    const regex = /^[^@]*@[^@]*$/;
    return regex.test(inputString);
  };

  // useEffect(() => {
  //   setSearchqueryWatches('');
  //   setSearchqueryAssighne('');
  // }, [showComponent]);

  const testArray = [
    {
      id: '1',
      title: 'ddddddddddda',
    },
    {
      id: '2',
      title: 'dddddddddddasdasdasdasd',
    },
    {
      id: '3',
      title: 'dddddddddddasdasdasdasd DAN',
    },
  ];

  const renderProfileTagsButtons = () => {
    // Return the JSX element conditionally based on props.permission
    return (
      <>
        <img src={RoiLogo} alt="" />
      </>
    );
  };

  console.log(contact, '------>contact[]');
  return (
    <>
      {showLoader && tabType !== labels.ASSIGNEE[selected_lang] && (
        <LoaderSpinner />
      )}
      <SlidingPanel
        renderData={renderProfileTagsButtons}
        showBlackHeader={true}
        lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
        }}
        hidePanel={() => {
          props.onClose();
        }}>
        <div className="serach-filter-wrapper">
          {/* <div className="ticket-icons-options">
            <ul>
              <li
                className={'active'}
                onClick={() => {
                  setTopIcons({
                    ...topIcons,
                    ['personal']: !topIcons.personal,
                  });
                  setShowComponent('persnal-model');
                }}>
                <PersnalSvg fill={'white'} />
              </li>
            </ul>
          </div> */}
          {/* bs */}

          <div className="d-flex align-items-center fixed-width-inputs fix-width-forms pt-3 black-head-form-pad-left black-head-form-pad-right justify-content-center  w-100">
            <div
              className="create-ticket-tabs custom-create-ticket-tabs"
              style={{
                width: 'auto',
              }}>
              <CustomTabs
                darkmode
                active={tabType}
                onSelect={item => {
                  if (
                    item === labels.ASSIGNEE[selected_lang] &&
                    (watchersBuilders.length === 0 ||
                      assigneeBuilders.length === 0)
                  ) {
                    setshowLoader(true);
                  }
                  setTabType(item);
                }}
                tabs={[
                  labels.sessions_type_general[selected_lang],
                  labels.ASSIGNEE[selected_lang],
                  labels.ADVANCE[selected_lang],
                ]}
              />
            </div>
          </div>
          <Scrollbars
            className="custom-scroll"
            onScroll={() => {
              setPickerOpen(false);
            }}>
            <div className="fixed-width-inputs black-head-form-pad-left black-head-form-pad-right">
              <div className="messages-users-list">
                <div className="create-ticket-form create-action-ticket-form">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    {tabType ===
                      labels.sessions_type_general[selected_lang] && (
                      <>
                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Title
                          </Form.Label>
                          <Form.Control
                            name="title"
                            value={title}
                            pattern="^\S.*$"
                            required
                            placeholder="Add task title"
                            onChange={e => {
                              // get_follow_ups_search(e.target.value);
                              setTitle(e.target.value);

                              getUserNames(e);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Title is required.
                          </Form.Control.Feedback>
                        </div>

                        {listOfSearch.length > 0 && (
                          <ul className="custom-suggestions">
                            {listOfSearch.map(suggestion => (
                              <li
                                className="as-link"
                                key={'suggestion' + suggestion.id}
                                onClick={() => {
                                  // objectValues(suggestion);
                                  // setTitle({`${suggestion.first_name} +' ' + ${suggestion.last_nam}`});
                                  const wordsArray = title.split(' ');

                                  wordsArray.splice(wordsArray.length - 1, 1);

                                  const input = wordsArray.join(' ');
                                  const finalTitle = input.replace(
                                    /@\s+/g,
                                    '@',
                                  );

                                  setTitle(
                                    `${finalTitle} @${suggestion.first_name} ${suggestion.last_name}`,
                                  );
                                  setListOfSearch('');
                                }}>
                                {suggestion.first_name}
                                {suggestion.last_name}
                              </li>
                            ))}
                          </ul>
                        )}

                        <div className="fieldset">
                          <div className="d-flex align-items-center justify-content-between">
                            <Form.Label style={{width: 'auto'}}>
                              Board
                            </Form.Label>

                            <div className="search-tabs-icons">
                              {addTask && taskBoard.length > 0 ? (
                                <div
                                  className="search-tab-action-icons"
                                  onClick={() => {
                                    setAddTask(!addTask);
                                  }}>
                                  <span>Cancel</span>
                                </div>
                              ) : (
                                !addTask && (
                                  <span
                                    className={addTask ? 'icon active' : 'icon'}
                                    onClick={() => {
                                      setAddTask(!addTask);
                                    }}>
                                    <i className="icon-plus"></i>
                                  </span>
                                )
                              )}
                            </div>
                          </div>

                          {!addTask ? (
                            <CustomDropdown
                              // type={'credential'}
                              setDropdownId={setTaskBoardId}
                              dropdownId={taskBoardId}
                              DropDownValues={taskBoard}
                              itemName={['title']}
                              className={'mb-3 recruitment_dropdown'}
                              // handleDropdownValue={handleAccomplishmentChange}
                              // disabled={props.id ? true : false}
                            />
                          ) : (
                            <div className="board-placeholder-field-container">
                              <Form.Control
                                name="title"
                                className="board-placeholder-field"
                                value={addTaskValue}
                                pattern="^\S.*$"
                                required
                                placeholder="Add board title"
                                onChange={e => {
                                  // get_follow_ups_search(e.target.value);
                                  setAddTaskValue(e.target.value);
                                }}
                              />
                              <span
                                className={addTask ? 'icon active' : 'icon'}
                                onClick={() => {
                                  add_task_board();
                                }}>
                                <i className="icon-plus"></i>
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="fieldset">
                          <div className="d-flex align-items-center justify-content-between">
                            <Form.Label style={{width: 'auto'}}>
                              Priority
                            </Form.Label>

                            <div className="">
                              <Form.Check
                                // disabled={props.id ? true : false}
                                className=""
                                type="switch"
                                id="custom-switch"
                                label={'High Impact'}
                                value={highImpact ? true : false}
                                checked={highImpact ? true : false}
                                onChange={() => {
                                  setHighImpact(!highImpact);
                                }}
                              />
                            </div>
                          </div>
                          <CustomDropdown
                            // type={'credential'}
                            setDropdownId={setPiorityListId}
                            dropdownId={piorityListid}
                            DropDownValues={piorityList}
                            itemName={['title']}
                            className={'mb-3 recruitment_dropdown'}
                            // handleDropdownValue={handleAccomplishmentChange}
                            // disabled={props.id ? true : false}
                          />
                        </div>

                        <Form.Group className="fieldset">
                          <Row>
                            <Col md={6}>
                              <div className="fieldset  mb-0">
                                <Form.Label controlid="validationCustom01">
                                  Due Date
                                </Form.Label>
                                {/* <Form.Control
                                  name="title"
                                  value={date}
                                  type="date"
                                  min={moment(serverTimeS).format('YYYY-MM-DD')}
                                  max={maxDate}
                                  placeholder="Add date"
                                  onChange={e => {
                                    setDate(e.target.value);
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {date !== ''
                                    ? 'Date is invalid.'
                                    : 'Date is required.'}
                                </Form.Control.Feedback> */}

                                <Calender
                                  minDate={moment(serverTimeS).format(
                                    'YYYY-MM-DD',
                                  )}
                                  maxDate={maxDate}
                                  setSelectedDate={selectedDate => {
                                    if (selectedDate) {
                                      setDate(selectedDate);
                                    } else {
                                      setDate('');
                                    }
                                  }}
                                  selectedDate={date ? new Date(date) : null}
                                  showDate={true}
                                  showTime={false}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="fieldset mb-0">
                                <Form.Label controlid="validationCustom01">
                                  Due Time
                                </Form.Label>
                                {/* <Form.Control
                                  // required
                                  name="title"
                                  value={time}
                                  type="time"
                                  // required
                                  placeholder="Add date"
                                  onChange={e => {
                                    setTime(e.target.value);
                                  }}
                                /> */}

                                <div className="time_picker createaction-custom-timepicker">
                                  {/* <TimePicker
                                    showSecond={false}
                                    value={
                                      time === ''
                                        ? ''
                                        : moment(time, 'HH:mm:ss')
                                    }
                                    onChange={e => {
                                      setTime(
                                        moment(e, 'HH:mm').format('HH:mm'),
                                      );
                                    }}
                                    format="h:mm a"
                                    use12Hours
                                    placeholder="--:-- --"
                                    allowEmpty={false}
                                    placement="bottomLeft"
                                    open={pickerOpen}
                                    onOpen={() => {
                                      setPickerOpen(true);
                                    }}
                                    onClose={() => {
                                      setPickerOpen(false);
                                    }}
                                  /> */}

                                  <Calender
                                    type="time"
                                    setStartDaysTime={e => {
                                      if (e) {
                                        setTime(
                                          moment(e, 'HH:mm A').format(
                                            'HH:mm A',
                                          ),
                                        );
                                      } else {
                                        setTime('');
                                      }
                                    }}
                                    startDaysTime={
                                      time === ''
                                        ? ''
                                        : moment(time, 'HH:mm:ss')
                                    } // Ensure the moment object is valid before passing
                                    showDate={false}
                                    showTime={true}
                                  />
                                </div>

                                {/* <Form.Control.Feedback type="invalid">
                                  Time is required.
                                </Form.Control.Feedback> */}
                              </div>
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="fieldset">
                          <Form.Label controlid="validationCustom01">
                            Description
                          </Form.Label>
                          <Form.Control
                            // required
                            value={discription}
                            as="textarea"
                            rows={6}
                            type="text"
                            placeholder="Let others know what to expect."
                            onChange={e => {
                              setDiscription(e.target.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Description is required.
                          </Form.Control.Feedback>
                        </div>

                        <Col sm={2}>
                          <div className="ticket-img-wrapper mb-3">
                            {/* <p onClick={()=>{setShowModel('modal')}}>Onclick</p> */}
                            {sliderImage.length > 0 && (
                              <p
                                onClick={() => {
                                  // setShowModel('image_preview');
                                  setModelImg(sliderImage[0]);
                                  setShowComponent('image_pre');
                                }}>
                                Onclick
                              </p>
                            )}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={e => changeTicketImg(e)}
                            />
                            {/* {ticketImg && <img src={ticketImg} alt="" />} */}

                            {/* {ticketImg && ticketImg.length > 0 &&
                      ticketImg.map((item, index) => <img src={item} alt="" />)} */}

                            {sliderImage && sliderImage.length > 0 && (
                              <img src={sliderImage[0]} alt="" />
                            )}
                            <i className="icon-camera" />
                          </div>

                          {/* {sliderImage.length > 0 && (
                            <span
                              className="as-link info-text mb-3 mt-0"
                              onClick={() => {
                                setShowModel('image_preview');
                              }}>
                              Preview
                            </span>
                          )} */}
                        </Col>

                        <Button
                          className="btn-dark"
                          type="submit"
                          style={{display: 'none'}}
                          ref={buttonRef}>
                          {props.editAction ? 'Update Task' : 'Create Task'}
                        </Button>
                      </>
                    )}
                    {tabType === labels.ASSIGNEE[selected_lang] && (
                      <>
                        {showLoader &&
                          tabType === labels.ASSIGNEE[selected_lang] && (
                            <LoaderSpinner />
                          )}

                        <div className="refferal-list-wrapper">
                          <p className="input-label m-0">Assignee</p>
                          <AssigneeBuilders
                            showMessagesUsers={
                              props.type === 'messages' ? true : false
                            }
                            showLoader={showLoader}
                            buildersHandle={assigneeHandle}
                            allChatUsers={filterCreater}
                            // buildersHandle={watchesHandle}
                            searchqueryAssighneCheck={searchqueryAssighneCheck}
                            type={'Create_Action'}
                            builders={assigneIds}
                            setshowLoader={setshowLoader}
                            // contact={[]}
                            AssighneBuildersShow={true}
                            searchqueryAssighne={searchqueryAssighne}
                            setSearchqueryAssighne={setSearchqueryAssighne}
                            contact={contact}
                            setContact={setContact}
                            setShowComponent={setShowComponent}
                            forApiHit={showComponent}
                            addContact={addContact}
                            setAddContact={setAddContact}
                            createTaskRef={assigneRef}
                          />
                          <div className="fieldset">
                            <Form.Control
                              // name="title"
                              value={searchqueryAssighne}
                              // pattern="^\S.*$"
                              // required
                              placeholder="Type assignee name"
                              onChange={e => {
                                setSearchqueryAssighne(e.target.value);
                                setSearchqueryAssighneCheck(true);
                                assigneRef.current.getAssignee(e.target.value);
                              }}
                            />
                            {/* <Form.Control.Feedback type="invalid">
                            Title is required.
                          </Form.Control.Feedback> */}
                          </div>
                          {Object.entries(assigneItem).length > 0 &&
                            assigneItem.map((item, index) => {
                              return (
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="input-label m-0">
                                    {fixName(item.first_name, item.last_name)}
                                  </p>
                                  <div className="text-right mb-2">
                                    <span className="circle-link-icon">
                                      <span
                                        className="as-link remove-selected-actions"
                                        onClick={() => {
                                          setAssigneIds([]);
                                          setAssigneItem([]);
                                        }}>
                                        <i class="fas fa-times"></i>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}

                          <div className="d-flex align-items-center justify-content-between">
                            <p className="input-label m-0">Watchers</p>
                            <div className="invite-persons-heading text-right">
                              <span
                                className="circle-link-icon"
                                onClick={() => {
                                  setShowComponent('circle');
                                }}>
                                {/* Circles  */}
                                <i className="icon-circles" />
                              </span>
                            </div>
                          </div>
                          <WatchesBuilders
                            // buildersHandle={assigneeHandle}
                            allChatUsers={filterCreater}
                            showMessagesUsers={
                              props.type === 'messages' ? true : false
                            }
                            showLoader={showLoader}
                            buildersHandle={watchesHandle}
                            type={'Create_Action'}
                            builders={watchesIds}
                            buildersItem={watchesItem}
                            setshowLoader={setshowLoader}
                            setWatchesItem={setWatchesItem}
                            originalWatchesItem={originalWatchesItem}
                            assigneIds={assigneIds}
                            // contact={[]}
                            searchqueryWatchesCheck={searchqueryWatchesCheck}
                            showSelected={showSelected}
                            setShowSelected={setShowSelected}
                            searchqueryWatches={searchqueryWatches}
                            contact={contact}
                            setContact={setContact}
                            setShowComponent={setShowComponent}
                            forApiHit={showComponent}
                            addContact={addContact}
                            setAddContact={setAddContact}
                            createTaskRef={watchersRef}
                          />
                          <div className="fieldset">
                            <Form.Control
                              name="title"
                              value={searchqueryWatches}
                              // pattern="^\S.*$"
                              // required
                              placeholder="Type watchers name"
                              onChange={e => {
                                setSearchqueryWatches(e.target.value);
                                setSearchqueryWatchesCheck(true);
                                watchersRef.current.getWatchers(e.target.value);
                              }}
                            />
                            {/* <Form.Control.Feedback type="invalid">
                            Title is required.
                          </Form.Control.Feedback> */}
                          </div>
                          <div className="invite-persons-heading">
                            <span
                              className={
                                showSelected
                                  ? 'builder-text as-link p-bold'
                                  : 'for-grey as-link p-bold'
                              }
                              onClick={() => {
                                setSearchqueryWatches('');
                                if(!showSelected && searchqueryWatches !==''){
                                  watchersRef.current.getWatchers('');
                                }
                                setShowSelected(!showSelected);
                               
                              }}>
                              {originalWatchesItem.length > 0
                                ? originalWatchesItem.length + ' Selected'
                                : ''}
                            </span>
                          </div>
                          {showSelected &&
                            Object.entries(originalWatchesItem).length > 0 &&
                            originalWatchesItem.map((item, index) => {
                              return (
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="input-label m-0">
                                    {fixName(item.first_name, item.last_name)}
                                  </p>
                                  <div className="text-right mb-2">
                                    <span className="circle-link-icon">
                                      <span
                                        className="as-link remove-selected-actions"
                                        onClick={() => {
                                          setWatchesIds(previous =>
                                            previous.filter(
                                              miniItem => miniItem !== item.id,
                                            ),
                                          );
                                          setWatchesItem(previous =>
                                            previous.filter(
                                              miniItem =>
                                                miniItem.id !== item.id,
                                            ),
                                          );

                                          setOriginalWatchesItem(previous =>
                                            previous.filter(
                                              miniItem =>
                                                miniItem.id !== item.id,
                                            ),
                                          );
                                        }}>
                                        <i class="fa-solid fa-circle-xmark"></i>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                    {tabType === labels.ADVANCE[selected_lang] && (
                      <div className="custom-checklist-style">
                        <div className="session-heading">
                          <h2>CheckList</h2>
                        </div>
                        <div className="custom-checklist-style-holder">
                          <Form.Label controlid="validationCustom01">
                            Title
                          </Form.Label>
                          <Form.Check
                            // disabled={props.id ? true : false}
                            className=""
                            type="switch"
                            id="custom-switch"
                            label={'Required'}
                            value={required ? true : false}
                            checked={required ? true : false}
                            onChange={() => {
                              setRequired(!required);
                            }}
                          />
                          <span
                            className="custom-checklist-plus-icon"
                            onClick={handleAddOrUpdateItem}>
                            <i className="icon-plus" />
                          </span>
                        </div>
                        <div className="">
                          <div className="fieldset">
                            <Form.Control
                              // required
                              value={checkListTitle}
                              type="text"
                              placeholder="Add Title"
                              onChange={e => {
                                setCheckListTitle(e.target.value);
                              }}
                            />
                          </div>
                          <div>
                            <div className="">
                              {Object.entries(checkListTitleArray).length > 0 &&
                                checkListTitleArray.map((item, index) => {
                                  return (
                                    <div
                                      className="d-flex align-items-center justify-content-between"
                                      style={{marginBottom: '7px'}}>
                                      <p className="input-label m-0">
                                        {item.title}
                                      </p>
                                      <div className="text-right mb-2">
                                        <div className="d-flex gap-2 ">
                                          <span
                                            className=" as-link"
                                            onClick={() => {
                                              handleEditDelete(
                                                item,
                                                index,
                                                'edit',
                                              );
                                            }}>
                                            <EditPencilSvg fill={'#52bd41'} />
                                          </span>

                                          <span
                                            className="poc_delete as-link"
                                            onClick={() => {
                                              handleEditDelete(
                                                item,
                                                index,
                                                'delete',
                                              );
                                            }}>
                                            <i className="fa-solid fa-trash-can" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </Scrollbars>
          {tabType === labels.sessions_type_general[selected_lang] && (
            <div className="filter-btn-wrapper mb-2">
              <div
                className={`invite-btn text-center bg_transparent bottom-0 ${
                  !props.primary ? 'fixed-width-inputs fix-width-forms' : ''
                }`}
                style={{paddingTop: '10px'}}>
                <Button
                  className="btn-dark"
                  type="submit"
                  onClick={() => {
                    buttonRef.current.click();
                  }}>
                  {props.followUpType === 'follow_up'
                    ? 'Create Follow Up'
                    : props.editAction
                    ? 'Update Task'
                    : 'Create Task'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>
      {showModel === 'crop_modal' && file && (
        <CustomModal
          size="large"
          onClose={() => {
            setShowModel('');
            setFile(null);
          }}>
          <CropImage
            type="action"
            ratio={429 / 187}
            file={file}
            setFile={setFile}
            setCropImg={setShowModel}
            imgUrl={ticketImg}
            setImgUrl={setTicketImg}
            sliderImage={sliderImage}
            setSliderImage={setSliderImage}
            setshowLoader={setshowLoader}
          />
        </CustomModal>
      )}

      {showModel === 'image_preview' && (
        <SlidingPanel hidePanel={() => setShowModel('')}>
          <div className="serach-filter-wrapper">
            <div className="permission-modal-content text-left">
              <h3>Images</h3>
            </div>

            <Scrollbars className="custom-scroll">
              <div className="snapshot-preview-wrapper fix-width-forms">
                <Row>
                  {Object.entries(sliderImage).length > 0 ? (
                    Object.entries(sliderImage).length > 0 &&
                    sliderImage.map((item, index) => {
                      return (
                        <Col md={4} sm={4} key={`image-${index}`}>
                          <div className="snapshot-item">
                            <span
                              className="remove-icon"
                              onClick={() => {
                                setIndexOfImage(index);
                                setShowComponent('image_remove_alert');
                                //
                                // setSliderImage(prevImgs => prevImgs.filter((_, i) => i !== index));
                                // // setImgUrl('');
                                // setTicketImg('');
                              }}>
                              <i className="fas fa-times" />
                            </span>

                            <img
                              className="as-link"
                              src={item}
                              alt=""
                              onClick={() => {
                                setModelImg(item);
                                setShowComponent('image_pre');
                              }}
                            />
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <p className="text-center">No Images Found</p>
                  )}
                </Row>
              </div>
            </Scrollbars>
          </div>
        </SlidingPanel>
      )}

      {showComponent === 'circle' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'persnal-model' && (
        <PersonalCreateModel
          list={list}
          setWorkSpaceIds={setWorkSpaceIds}
          workSpaceIds={workSpaceIds}
          setPiorityList={setPiorityList}
          onClose={() => {
            setShowComponent('');
            setTopIcons({
              ...topIcons,
              personal: !topIcons.personal,
            });
          }}
        />
      )}
      {showComponent === 'image_pre' && modelImg && (
        <ImagePreview
          modelImg={modelImg}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'image_remove_alert' && (
        <AlertModel
          // modelImg={modelImg}
          onClose={() => {
            setShowComponent('');
          }}
          removeImage={() => {
            setSliderImage(prevImgs =>
              prevImgs.filter((_, i) => i !== indexOfImage),
            );
            // setImgUrl('');
            setTicketImg('');
          }}
        />
      )}

      {showComponent === 'inviteUser' && (
        <SendInvitation
          type={'action'}
          // contact={contact}
          setContact={setContact}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};
export default CreateAction;
