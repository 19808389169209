import React, {Fragment} from 'react';
import {Button, Form} from 'react-bootstrap';
import {selected_lang} from '../../Utils/env';
import {labels} from '../../Constants/Translations';

import {useState, useEffect, useRef} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {Templates} from '../../Constants/Images';
import InvoiceEstimate from './InvoiceEstimate';
import commonApi from '../../Services/CommonService';
import {
  addProductQuantity,
  calculateItemSubtotal,
  calculateSubtotal,
  capitalizeFirst,
  checkPermissions,
  extractFirstLevelAndFrequency,
  checkProductQuantity,
  updateOutOfStockStatus,
  checkProductStock,
  checkAvailablitiy,
  fixName,
  processCatalogs,
  validatePaymentMilestone,
  validateCatalogsDiscount,
  validatePaymentMilestoneDates,
  getMaxDateFromToday,
} from '../../Utils/commonUtils';

import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
import Invite from '../SingleComponent/Invite';
import CustomModal from '../CustomModal';
import InvoiceTemplates from './InvoiceTemplates';

import SendInvitation from '../../Pages/Front/StaticPages/SendInvitation';
import CircleListBuilders from '../../Pages/Front/StaticPages/CircleListBuilders';
import {
  formatNumberWithTwoDecimals,
  formatMinutesTime,
  checkSubscriptionType,
  formatValueWithTwoDecimals,
  calculateTotal,
} from '../../Utils/commonUtils';
import LoaderSpinner from '../Modals/LoaderSpinner';

import {trigger} from '../../Utils/commonUtils';
import {NoOfferingsFound} from '../../Utils/NoRecordConstant';
import NorecordFound from '../NoRecord/NorecordFound';
import {useSelector} from 'react-redux';
import CreateCatalog from '../Catalog/CreateCatalog';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import InvoiceProduct from './InvoiceProduct';
import CustomTabs from '../CustomTabs';
import CreateExpense from './CreateExpense';
import InvoiceExpense from './InvoiceExpense';
import { RoiLogo } from '../../Constants/Images';
import Calender from '../FeedBack/Calender';

const CreateInvoice = props => {
  // const [type, setType] = useState('1');
  const menuPermesions = useSelector(state => state.menuPermesionList);

  const [tabType, setTabType] = useState(
    props.subType || props.tabType === 'estimates' ? 'estimates' : 'invoices',
  );
  const [mainTab, setMainTab] = useState(
    labels.sessions_type_general[selected_lang],
  );
  const [showSearchProduct, setShowSearchProduct] = useState('');
  const [productDetail, setProductDetail] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [catalogIds, setCatalogIds] = useState([]);
  const [builders, setBuilders] = useState([]);

  const [allBuilders, setAllBuilders] = useState([]);
  const [originalDataOfMod, setOriginalDataOfMod] = useState([]);
  const [contact, setContact] = useState([]);
  const [hitApiAddedUser, setHitApiAddedUser] = useState();

  const [comparisonBuilders, setComparisonBuilders] = useState([]);
  const [allComparisonBuilders, setAllComparisonBuilders] = useState([]);
  const [originalDataOfComparison, setOriginalDataOfComparsion] = useState([]);

  const [standardbuilders, setStandardBuilders] = useState([]);
  const [allStandardBuilders, setAllStandardBuilders] = useState([]);
  const [originalDataOfStandard, setOriginalDataOfStandard] = useState([]);
  const [invoiceUsers, setInvoiceUsers] = useState([]);
  const [compUsers, setCompUsers] = useState([]);
  const [standUsers, setStandUsers] = useState([]);
  const [invoiceService, setInvoiceService] = useState('');
  const [showComponent, setShowComponent] = useState('');
  const [showModel, setShowModel] = useState('');
  const [showInvoice, setShowInvoice] = useState('');

  const [comparisonTitle, setComparisonTitle] = useState('');
  const [comparisonList, setComparisonList] = useState([]);
  const [invoiceDraft, setInvoiceDraft] = useState({});

  const [templatesList, setTemplatesList] = useState([]);
  const [comparisonTemplates, setComparisonTemplates] = useState([]);
  const [standardTemplates, setStandardTemplates] = useState([]);
  const [templateItem, setTemplateItem] = useState({});
  const [comparisonTemplateItem, setComparisonTemplateItem] = useState({});
  const [expensesList, setExpensesList] = useState([]);
  const [expenseItem, setExpenseItem] = useState({});
  // const [estimateService, setEstimateService] = useState('');

  const [moreOptions, setMoreOptions] = useState(false);
  const [template, setTemplate] = useState(false);
  const [draft, setDraft] = useState(false);
  const [invoiceName, setInvoiceName] = useState('');
  const [userSearchQry, setUserSearchQry] = useState('');
  const [note, setNote] = useState('');
  const [cost, setCost] = useState('0.00');
  const [deleteCatalog, setDeleteCatalog] = useState({});
  const [deleteCatalogItem, setDeleteCatalogItem] = useState(false);
  const [initialRender, setInitialRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const [produtAvailability, setProductAvailabilty] = useState([]);
  const [showErrorFromApi, setShowErrorFromApi] = useState([]);
  const [outOfStockItems, setOutOfStockItems] = useState([]);

  const [invoiceDate, setInvoiceDate] = useState(() => {
    const date = new Date();

    // date.setHours(12, 0, 0, 0);
    return date;
  });
  const [dueDate, setDueDate] = useState(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    // nextDay.setHours(12, 0, 0, 0);
    return nextDay;
  });

  const [time, setTime] = useState('10:00');
  const [addUser, setAddUser] = useState('');

  const [titleValidation, setTitleValidation] = useState(false);

  const [width, setWidth] = useState(34);
  const calendarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [endIsOpen, setEndIsOpen] = useState(false);
  const [invoiceData, setInVoiceData] = useState({
    name: '',
    subscripton: '0.00',
    subtotal: '0.00',
    tax: '0.00',
    discount: '0.00',
    shipping: '0.00',
    total: '0.00',
    payment_Schedule: '',
  });

  const taxRef = useRef(null);
  const discountRef = useRef(null);
  const shippingRef = useRef(null);

  const comparisonModalRef = useRef();
  const standardModalRef = useRef();

  const templateRef = useRef();
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const userDropdownRef = useRef(null);
  const userInputRef = useRef(null);

  const [shippingWidth, setShippingW] = useState(34);
  const [discountWidth, setDiscountW] = useState(34);
  const [subType, setSubType] = useState(
    props.subType === 'standard' ? 'standard' : 'comparison',
  );

  const [itemIndex, setItemIndex] = useState(34);
  const [comparsionItem, setComparisonItem] = useState({});
  const [standardItem, setStandardItem] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  const [followers, setFollowers] = useState([]);

  const [noResults, setNoResults] = useState(false);
  const RadioButtonName = ['OR', 'AND'];
  const [addOnValue, setAddOnValue] = useState(RadioButtonName[0]);

  const [inviteLoader, setInviteLoader] = useState(true);
  const [inviteFollowers, setInviteFollowers] = useState([]);
  const [followersList, setFollowersList] = useState([]);
  const [inviteQry, setInviteQry] = useState('');

  const ticketTabs = [
    labels.sessions_type_general[selected_lang],
    labels.invoice_expenses[selected_lang],
  ];

  const OfferingsPermissions =
    menuPermesions && checkPermissions(menuPermesions, 'Catalog');

  const checkPermesionOfCreate =
    OfferingsPermissions &&
    (OfferingsPermissions === 'allow' || OfferingsPermissions?.create === true)
      ? true
      : false;

  useEffect(() => {
    if (!props.templateItem) {
      getInvoiceDraft();
    }

    get_expenses_for_invoices('')
    getInvoiceTemplates();
  }, []);


  const get_expenses_for_invoices = value => {
    const payload = {search_query: value};
    commonApi
      .get_expenses(payload)
      .then(res => {
        if (res.status === 200) {
            setExpensesList(res.data)
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };



  // useEffect(() => {
  //   if (!initialRender) {
  //     if (taxRef.current) {
  //       setWidth(taxRef.current.offsetWidth + 2);
  //     }
  //     if (discountRef.current) {
  //       setDiscountW(discountRef.current.offsetWidth + 2);
  //     }
  //     if (shippingRef.current) {
  //       setShippingW(shippingRef.current.offsetWidth + 2);
  //     }
  //   }
  // }, [invoiceData, moreOptions]);

  const handleInputChange = e => {
    const {name, value} = e.target;

    if (parseFloat(value) > 1000000000000000) {
      return;
    }

    const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
    // const regex = /^[1-9]\d*(\.\d{0,2})?$|^0\.\d{0,2}$/;
    // const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
    if (value === '' || regex.test(value) || value === '0') {
      if (name === 'discount') {
        const discountValue = parseFloat(value);
        const subtotalValue = parseFloat(invoiceData.subtotal);
        const taxValue = parseFloat(invoiceData.tax);
        const shippingValue = parseFloat(invoiceData.shipping);

        if (discountValue >= subtotalValue + taxValue + shippingValue) {
          errorMessage('Discount cannot exceed total.');
          return;
        }
      }
      setInVoiceData({...invoiceData, [name]: value === '' ? '' : value});
    }
  };

  const handleBlur = event => {
    const {name, value} = event.target;
    let parsedValue = parseFloat(value.trim());
    let formattedValue = isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);

    setInVoiceData({...invoiceData, [name]: formattedValue});
  };

  const addComparison = title => {
    setComparisonList(prev => [
      ...prev,
      {
        group_name: title,
        productList: [],
      },
    ]);

    setComparisonTitle('');
  };

  // const dropdownMenu = [
  //   {id: '1', name: 'Comparison'},
  //   {
  //     id: '2',
  //     name: 'Standard',
  //   },

  // ];

  useEffect(() => {
    if (selectedProduct.length > 0) {
      setInVoiceData({
        ...invoiceData,
        total:
          Number(invoiceData.subtotal) +
          Number(invoiceData.tax) +
          Number(invoiceData.shipping) -
          Number(invoiceData.discount),
      });
    }
  }, [invoiceData.tax, invoiceData.shipping, invoiceData.discount]);

  const get_catalogs_for_invoices = value => {
    const payload = {search_query: value, type: 'web'};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          // if (!value || value === '' ) {
          //   setProductDetail([]);
          // } else {

          setProductDetail(res.catalog_data);
          // }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_users_for_invoices = value => {
    const payload = {search_query: value};
    commonApi
      .excluded_followers_list(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.data.length === 0) {
            setNoResults(true);
          } else {
            setNoResults(false);
          }

          if (!value || value === '') {
            setUserDetail([]);
          } else {
            setUserDetail(res.data);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  useEffect(() => {
    if (
      !props.subType &&
      props.templateItem &&
      props.templateItem.catalog_details &&
      props.templateItem.catalog_details.length > 0
    ) {
      setInvoiceName(props.templateItem.invoice_title);
      setCatalogIds(props.templateItem.catalog_ids.split(','));
      const updatedArray = calculateSubtotal(
        props.templateItem.catalog_details.length > 0 &&
          props.templateItem.catalog_details,
      );

      // console.log(updatedArray,'--------->updatedArrayTemplate')
      setSelectedProduct(updatedArray);

      const updatedInvoiceData = {
        name: '',
        subscripton: Number(props.templateItem.sub_total),
        subtotal: Number(props.templateItem.sub_total),
        tax: Number(props.templateItem.taxes_fee),
        discount: Number(props.templateItem.discount),
        shipping: Number(props.templateItem.shipping_handling),
        total: Number(props.templateItem.total),
        payment_Schedule: '',
      };

      setNote(props.templateItem.customer_note);
      setInVoiceData(updatedInvoiceData);
      setBuilders(
        props.templateItem?.draft_user_ids
          ? props.templateItem?.draft_user_ids.split(',')
          : [],
      );
      setInvoiceUsers(
        props.templateItem?.draft_user_ids
          ? props.templateItem?.draft_user_ids.split(',')
          : [],
      );

      // setInvoiceDate(new Date(props.templateItem.invoice_date));
      // setDueDate(new Date(props.templateItem.due_date));
    }

    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
    if (props.subType && props.subType === 'comparison') {
      // templateRef.current.addTemplateItem(templateItem);
    }
  }, [props.templateItem]);

  const getInvoiceDraft = () => {
    const payload = {invoice_type: 'invoice', sub_type: ''};
    commonApi
      .check_invoice_draft(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.draft_invoice.length > 0) {
            setInvoiceDraft(res.draft_invoice[0]);
            handleInvoiceDraft(res.draft_invoice[0]);
          }
          setTimeout(() => {
            setShowLoader(false);
          }, 300);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  // const getPermissionDraft = () => {
  //   const payload = {invoice_type: 'estimate', sub_type: 'comparison'};
  //   commonApi
  //     .check_invoice_draft(payload)
  //     .then(res => {
  //       if (res.status === 200) {
  //         if (res.draft_invoice.length > 0) {
  //           setPermissionDraft(res.draft_invoice[0])

  //         }
  //       }
  //     })
  //     .catch(err => {
  //       console.log('Err', err);
  //       return {type: 'error', message: err.message};
  //     });
  // };

  const handleInvoiceDraft = draftItem => {
    if (!props.type) {
      if (Object.keys(draftItem).length > 0) {
        const updatedArray = calculateSubtotal(
          draftItem?.catalog_details.length > 0 && draftItem?.catalog_details,
        );

        // console.log(updatedArray,'----------->updatedArray')
        setInvoiceName(draftItem?.invoice_title);
        setCatalogIds(draftItem?.catalog_ids.split(','));
        setSelectedProduct(updatedArray);

        const updatedInvoiceData = {
          name: '',
          subscripton: Number(draftItem?.sub_total),
          subtotal: Number(draftItem?.sub_total),
          tax: Number(draftItem?.taxes_fee).toFixed(2),
          discount: Number(draftItem?.discount).toFixed(2),
          shipping: Number(draftItem?.shipping_handling).toFixed(2),
          total: Number(draftItem?.total),
          payment_Schedule: '',
        };

        setNote(draftItem?.customer_note);
        setInVoiceData(updatedInvoiceData);

        // setInvoiceDate(new Date(draftItem?.invoice_date));
        // setDueDate(new Date(draftItem?.due_date));
        setBuilders(
          draftItem?.draft_user_ids ? draftItem?.draft_user_ids.split(',') : [],
        );
        setInvoiceUsers(
          draftItem?.draft_user_ids ? draftItem?.draft_user_ids.split(',') : [],
        );
      } else {
        setInvoiceName('');
        setCatalogIds('');
        setSelectedProduct([]);
        setInVoiceData({
          name: '',
          subscripton: '0.00',
          subtotal: '0.00',
          tax: '0.00',
          discount: '0.00',
          shipping: '0.00',
          total: '0.00',
          payment_Schedule: '',
        });

        setNote('');

        const date = new Date();

        // date.setHours(12, 0, 0, 0);

        setInvoiceDate(date);

        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        // nextDay.setHours(12, 0, 0, 0);
        setDueDate(nextDay);

        setBuilders([]);
        setInvoiceUsers([]);
      }
    }
  };

  const getInvoiceTemplates = () => {
    const payload = {invoice_type: 'invoice', sub_type: ''};

    commonApi
      .check_invoice_templates(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.templtes_invoices.length > 0) {
            setTemplatesList(res.templtes_invoices);
          }

          // setTemplatesList()
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleTemplates = () => {
    getEstimateTemplates(subType);

    setTimeout(() => {
      getEstimateTemplates('standard');
    }, 1500);
  };

  useEffect(() => {
    if (props.tabType === 'estimates') {
      handleTemplates();
    }
  }, []);

  useEffect(() => {
    if (contact.length > 0) {
      setInviteLoader(true);
      setInviteQry('');
    }
  }, [contact]);

  const getEstimateTemplates = subtype => {
    const payload = {invoice_type: 'estimate', sub_type: subtype};

    commonApi
      .check_invoice_templates(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.templtes_invoices.length > 0) {
            if (subtype === 'comparison') {
              setComparisonTemplates(res.templtes_invoices);
            } else {
              setStandardTemplates(res.templtes_invoices);
            }
          } else {
          }

          // setTemplatesList()
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleUsers = mod => {
    if (!builders.includes(mod.id)) {
      setBuilders(previous => [...previous, mod.id]);
      setInvoiceUsers(previous => [...previous, mod.id]);
      setAllBuilders(previous => [...previous, mod]);
      setOriginalDataOfMod(previous => [...previous, mod]);
      setUserSearchQry('');
      setUserDetail([]);
    } else {
      errorMessage('This user is already selected');
    }
  };

  const handleComparisonUsers = mod => {
    if (!comparisonBuilders.includes(mod.id)) {
      setComparisonBuilders(previous => [...previous, mod.id]);
      setCompUsers(previous => [...previous, mod.id]);
      setAllComparisonBuilders(previous => [...previous, mod]);
      setOriginalDataOfComparsion(previous => [...previous, mod]);
      setUserSearchQry('');
      setUserDetail([]);
    } else {
      errorMessage('This user is already selected');
    }
  };

  const handleStandardUsers = mod => {
    if (!standardbuilders.includes(mod.id)) {
      setStandardBuilders(previous => [...previous, mod.id]);
      setStandUsers(previous => [...previous, mod.id]);
      setAllStandardBuilders(previous => [...previous, mod]);
      setOriginalDataOfStandard(previous => [...previous, mod]);
      setUserSearchQry('');
      setUserDetail([]);
    } else {
      errorMessage('This user is already selected');
    }
  };

  const objectValues = item => {
    let outOfStockCount = 0;

    const processItem = miniItem => {
      let checkQuantity = checkProductQuantity(produtAvailability, miniItem);

      if (checkQuantity) {
        setSelectedProduct(prev => [
          ...prev,
          {
            title: miniItem.title ? miniItem.title : '',
            catalog_id: miniItem.id ? miniItem.id : '',
            taxes_fee: miniItem.taxes_fee
              ? formatValueWithTwoDecimals(miniItem.taxes_fee)
              : '0.00',
            discount: miniItem.discount
              ? formatValueWithTwoDecimals(miniItem.discount)
              : '0.00',
            shipping_handling: miniItem.shipping_handling
              ? formatValueWithTwoDecimals(miniItem.shipping_handling)
              : '0.00',
            late_fee: miniItem.late_fee
              ? formatValueWithTwoDecimals(miniItem.late_fee)
              : '0.00',
            cost: miniItem.cost ? miniItem.cost : '0.00',
            duration: miniItem.duration ? miniItem.duration : '',
            grace_period: miniItem.grace_period ? miniItem.grace_period : '',
            catalog_type: miniItem.catalog_type ? miniItem.catalog_type : '',
            // subscription_month: miniItem.catalog_type === 'membership' ? extractFirstLevelAndFrequency(miniItem)?.subscription : miniItem.subscription
            //   ? miniItem.subscription
            //   : '',
            subscription_month: miniItem.subscription,
            total: calculateItemSubtotal(miniItem),
            payment_milestones: miniItem.catalog_type === 'membership' ? extractFirstLevelAndFrequency(miniItem)?.frequency?.payment_milestones || [] :
              miniItem.payment_milestones &&
              miniItem.payment_milestones.length > 0
                ? miniItem.payment_milestones
                : [],
                payment_type: miniItem.catalog_type === 'membership' ? extractFirstLevelAndFrequency(miniItem)?.frequency?.payment_type || []:
                miniItem.payment_type && miniItem.payment_type.length > 0
                  ? miniItem.payment_type
                  : [],
            // payment_milestone_id:
            //   miniItem.payment_milestones &&
            //   miniItem.payment_milestones.length > 0
            //     ? [miniItem.payment_milestones[0].id]
            //     : [],
            ...(miniItem.catalog_type === 'membership' &&
            miniItem.membership_levels.length > 0
              ? {
                  level: extractFirstLevelAndFrequency(miniItem),
                  membership_levels:
                    miniItem.catalog_type === 'membership' &&
                    miniItem.membership_levels.length > 0
                      ? miniItem.membership_levels
                      : [],
                }
              : {}),
            ...(miniItem.catalog_type === 'product' && {
              out_of_stock: false,
            }),
            ...(miniItem.catalog_type === 'product' && {
              left_item: item.left_item,
            }),
            ...(miniItem.catalog_type === 'product' && {
              quantity: item.quantity,
            }),
            ...(miniItem.catalog_type === 'product' && {
              total_used: item.total_used,
            }),
          },
        ]);
        setCatalogIds(prev => [...prev, miniItem.id]);
        if (miniItem.catalog_type === 'product') {
          const productsQuantity = addProductQuantity(
            miniItem.id,
            produtAvailability,
          );
          setProductAvailabilty(productsQuantity);
        }
        setShowSearchProduct('');
      } else {
        outOfStockCount++;
      }

      setInVoiceData(prevData => ({
        ...prevData,
        subscripton: Number(prevData.subscripton) + Number(miniItem.cost),
        subtotal: Number(prevData.subtotal) + Number(miniItem.cost),
        total: Number(prevData.total) + Number(miniItem.cost),
      }));
    };

    if (item.catalog_details && item.catalog_details.length > 0) {
      item.catalog_details.forEach(miniItem => {
        processItem(miniItem);
      });
    } else {
      let checkQuantity = checkProductQuantity(produtAvailability, item);
      if (checkQuantity) {
        setSelectedProduct(prev => [
          ...prev,
          {
            title: item.title ? item.title : '',
            catalog_id: item.id ? item.id : '',
            taxes_fee: item.taxes_fee
              ? formatValueWithTwoDecimals(item.taxes_fee)
              : '0.00',
            discount: item.discount
              ? formatValueWithTwoDecimals(item.discount)
              : '0.00',
            shipping_handling: item.shipping_handling
              ? formatValueWithTwoDecimals(item.shipping_handling)
              : '0.00',
            late_fee: item.late_fee
              ? formatValueWithTwoDecimals(item.late_fee)
              : '0.00',
            cost: item.cost ? item.cost : '0.00',
            duration: item.duration ? item.duration : '',
            grace_period: item.grace_period ? item.grace_period : '',
            catalog_type: item.catalog_type ? item.catalog_type : '',
            subscription_month: item.subscription ? item.subscription : '',
            total: calculateItemSubtotal(item),
            payment_milestones:
              item.payment_milestones && item.payment_milestones.length > 0
                ? item.payment_milestones
                : [],
                payment_type:
              item.payment_type && item.payment_type.length > 0
                ? item.payment_type
                : [],
            // payment_milestone_id:
            //   item.payment_milestones && item.payment_milestones.length > 0
            //     ? [item.payment_milestones[0].id]
            //     : '',
            membership_levels:
              item.catalog_type === 'membership' &&
              item.membership_levels.length > 0
                ? item.membership_levels
                : [],

            ...(item.catalog_type === 'product' && {
              out_of_stock: false,
            }),
            ...(item.catalog_type === 'product' && {
              left_item: item.left_item,
            }),
            ...(item.catalog_type === 'product' && {
              quantity: item.quantity,
            }),
            ...(item.catalog_type === 'product' && {
              total_used: item.total_used,
            }),
          },
        ]);
        setCatalogIds(prev => [...prev, item.id]);

        if (item.catalog_type === 'product') {
          const productsQuantity = addProductQuantity(
            item.id,
            produtAvailability,
          );
          setProductAvailabilty(productsQuantity);
        }
        setShowSearchProduct('');
        get_catalogs_for_invoices('');
      } else {
        outOfStockCount++;
      }
      setInVoiceData({
        ...invoiceData,
        subscripton: Number(invoiceData.subscripton) + Number(item.cost),
        subtotal: Number(invoiceData.subtotal) + Number(item.cost),
        total: Number(invoiceData.total) + Number(item.cost),
      });
    }

    if (outOfStockCount > 1) {
      errorMessage(
        `${outOfStockCount} items ${labels.OUT_OF_STOCK[selected_lang]}`,
      );
    }
    if (outOfStockCount === 1) {
      errorMessage(`${labels.OUT_OF_STOCK[selected_lang]}`);
    }
  };

  const handleSubmit = type => {
    // const currentDate = new Date();
    // const dateToCheck = new Date(dueDate);
    // const invoicedateToCheck = new Date(invoiceDate);
    // let checkDate = currentDate < dateToCheck;

    // let invoiceDateCheck = currentDate < invoicedateToCheck;
    // console.log(invoiceDateCheck, 'invoiceDateCheck');

    // console.log(
    //   !checkDate && dueDate,
    //   !invoicedateToCheck && invoiceDate !== '',
    //   'check',
    // );

    const currentDate = new Date();
    const dueDateCheck = new Date(dueDate);
    const invoiceDateCheck = new Date(invoiceDate);

    let checkDate =
      // (currentDate <= invoiceDateCheck && currentDate <= dueDateCheck) ||
      invoiceDateCheck <= dueDateCheck;
    
    if (selectedProduct.length > 0 && invoiceName !== '') {
      if (dueDate !== '' && invoiceDate !== '' && checkDate) {
        if (builders.length > 0 || type === 'template' || type === 'draft') {
          sendInvoice(type);
        } else {
          errorMessage('Please select Users');
        }
      } else {
        errorMessage(
          !checkDate && dueDate !== ''
            ? 'Please select valid date'
            : 'Please select Date',
        );
      }
    } else {
      errorMessage('Please add title');
    }
  };

  const showOutOfStockError = (item, itemIndex) => {
    const newData = [...outOfStockItems];

    let itemToUpdate = outOfStockItems.find(obj => obj.id == item.catalog_id);

    if (itemToUpdate) {
      let allowedIndexs = itemToUpdate.allowed ? itemToUpdate.allowed : [];
      let errorIndexs = itemToUpdate.not_allowed
        ? itemToUpdate.not_allowed
        : [];
      const quantity = builders.length > 0 ? builders.length : 1;
      // const quantity = 1;
      itemToUpdate.total_used = Number(itemToUpdate.total_used) + quantity;
      itemToUpdate.item_left = Number(itemToUpdate.item_left) - quantity;

      if (Number(itemToUpdate.item_left) >= 0) {
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id,
        );
        newData[index] = itemToUpdate;

        allowedIndexs.push(itemIndex);
        itemToUpdate.allowed = allowedIndexs;

        setOutOfStockItems(newData);
      } else if (
        !allowedIndexs.includes(itemIndex) &&
        !errorIndexs.includes(itemIndex)
      ) {
        const index = outOfStockItems.findIndex(
          obj => obj.id == item.catalog_id,
        );
        newData[index] = itemToUpdate;

        errorIndexs.push(itemIndex);
        itemToUpdate.not_allowed = errorIndexs;

        setOutOfStockItems(newData);
      }

      return itemToUpdate;
    } else {
      return {not_allowed: []};
    }
  };

  const sendInvoice = async type => {
    const checkProductAvailablitiy = checkAvailablitiy(
      'invoice',
      selectedProduct,
      builders,
      [],
    );

    // console.log(
    //   checkProductAvailablitiy,
    //   'checkProductAvailablitiycheckProductAvailablitiy',
    // );
    if (checkProductAvailablitiy.length > 0) {
      setOutOfStockItems(checkProductAvailablitiy);
      if (checkProductAvailablitiy.length > 0 && builders.length > 1) {
        errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
      }
      return;
    }

    const Total = calculateTotal(selectedProduct);

    const filterOutLevels = catalogDetails => {
      return catalogDetails.map(detail => {
        const newDetail = {...detail};

        delete newDetail.membership_levels;
        // delete newDetail.payment_milestones;

        return newDetail;
      });
    };

    // console.log(selectedProduct,'------------>selectedProductselectedProductselectedProductselectedProduct')
    // const filteredCatalogDetails = processCatalogs(selectedProduct);
    const filteredCatalogDetails = await processCatalogs(selectedProduct);

    const validateMilestone =  validatePaymentMilestone(filteredCatalogDetails)
    const validateDisount =  validateCatalogsDiscount(filteredCatalogDetails)
    const validateDueDate = validatePaymentMilestoneDates(filteredCatalogDetails,dueDate)
    // console.log(validateMilestone,'------->validateMilestonevalidateMilestone')
    if(!validateMilestone){
      errorMessage('Sum of payment milestones must be equal to cost')
      return
    }

    if(validateDisount){
      errorMessage(labels.discount_Error[selected_lang]);
      return
    }

    if(!validateDueDate){
      errorMessage(labels.invalid_due_date[selected_lang]);
      return
    }
    // console.log(validateDueDate,'----------->validateDatevalidateDate')
    var payload = {
      inserted_data: [
        {
          invoice_title: invoiceName,
          invoice_type: 'invoice',
          sub_type: '',
          // catalog_ids: catalogIds.toString(','),
          group_name: '',
          // sub_total: invoiceData.subtotal.toString(),
          // taxes_fee: invoiceData.tax.toString(),
          // discount: invoiceData.discount.toString(),
          // shipping_handling: invoiceData.shipping.toString(),
          catalog_details: filteredCatalogDetails,
          // total: invoiceData.total.toString(),
          total: Total ? Total.toString() : '0.00',
          is_template: type === 'template' ? true : false,
          is_draft: type === 'draft' ? true : false,
          add_on: '',
          invoice_date: invoiceDate
            ? Moment(invoiceDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
          due_date: dueDate
            ? Moment(dueDate).format('YYYY-MM-DD HH:mm:ss').toString()
            : '',
          customer_note: note,
        },
      ],

      deal_id: props.type === 'deals' ? props?.dealItem.id : '',

      user_id: builders,

      // ...(type === '' ? {user_id: builders} : {}),
    };

    commonApi
      .send_invoice_estimate(payload)
      .then(res => {
        if (res.success && !res.error) {
          successMessage(res.message);
          if (type !== 'template') {
            if (props.type === 'deals') {
              props.hitUserUserInfo();
            }

            if (props.type === 'profile') {
              props.hitApiRefresh();
            }
            props.onClose();
          }

          if (type === 'template') {
            getInvoiceTemplates();
          }
          if (props.type && type === 'template') {
            props.getTemplates();
          }

          if (props.type && type === 'draft') {
            props.getDraft();
          }
        }
        if (res.error && !res.user_issue) {
          // errorMessage(`${labels.QUANTITY_NOT_AVAILABLE[selected_lang]}`);
          if (res.not_available.length > 0) {
            setOutOfStockItems(res.not_available);
          }
        }
        if (res.error && res.user_issue) {
          if (res.not_available.length > 0) {
            setOutOfStockItems(res.not_available);
          }
          errorMessage(`${labels.OUT_OF_STOCK_FOR_USERS[selected_lang]}`);
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleClickOutside = event => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowSearchProduct('');
      setProductDetail([]);
    }
    if (
      userInputRef.current &&
      !userInputRef.current.contains(event.target) &&
      userDropdownRef.current &&
      !userDropdownRef.current.contains(event.target)
    ) {
      setUserSearchQry('');
      setUserDetail([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [showSearchProduct, userSearchQry]);
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  //   if (date.getDate() === dueDate.getDate()) {
  //     // If the selected date is the same as the due date, set the time to the due time
  //     setSelectedDate(new Date(date.getFullYear(), date.getMonth(), date.getDate(), dueDate.getHours(), dueDate.getMinutes()));
  //   }
  // };

  const handleChange = e => {
    if (endIsOpen === true) {
      setDueDate(e);
      setEndIsOpen(false);
    } else if (e) {
      setInvoiceDate(e);
      setIsOpen(false);
    } else {
      setIsOpen(false);
    }
  };

  const handleClickD = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
    // calendarRef?.current?.click();
    setEndIsOpen(false);
  };
  const handleClickE = e => {
    e.preventDefault();
    setEndIsOpen(!endIsOpen);
    // calendarRef?.current?.click();
    setIsOpen(false);
  };

  const handleOnClickOutside = () => {
    setIsOpen(false);
    setEndIsOpen(false);
  };

  const removeProduct = (item, itemIndex) => {
    // const updatedProducts = selectedProduct.filter(
    //   (_, index) => index !== itemIndex,
    // );

    // const updateItems = updateOutOfStockStatus(
    //   updatedProducts,
    //   showErrorFromApi,
    // );
    // setSelectedProduct(updateItems);
    setSelectedProduct(previous =>
      previous.filter((_, index) => index !== itemIndex),
    );

    setCatalogIds(previous =>
      previous.filter((_, index) => index !== itemIndex),
    );

    setInVoiceData({
      ...invoiceData,
      subscripton: Number(invoiceData.subscripton) - Number(item.cost),
      subtotal: Number(invoiceData.subtotal) - Number(item.cost),
      total: Number(invoiceData.total) - Number(item.cost),
    });

    setDeleteCatalog({});
  };

  const removeMileStone = (itemIndex,mileStoneIndex) =>{
    const newSelectedProduct = [...selectedProduct];
    const catalogItem =
    newSelectedProduct[itemIndex]
    catalogItem.payment_milestones.splice(mileStoneIndex, 1);

    
    setSelectedProduct(newSelectedProduct)
  }

  const handleCircle = () => {
    setShowComponent('CircleList');
  };

  const CircleUserListData = item => {
    if (addUser === 'addUser') {
      if (item.length > 0) {
        item.forEach((miniItem, index) => {
          if (!builders.includes(miniItem.id)) {
            setBuilders(previous => [...previous, miniItem.id]);
            setAllBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfMod(previous => [...previous, miniItem]);
          }
        });
      }
    } else if (addUser === 'comparisonUsers') {
      if (item.length > 0) {
        item.forEach((miniItem, index) => {
          if (!comparisonBuilders.includes(miniItem.id)) {
            setComparisonBuilders(previous => [...previous, miniItem.id]);
            setAllComparisonBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfComparsion(previous => [...previous, miniItem]);
          }
        });
      }
    } else {
      if (item.length > 0) {
        item.forEach((miniItem, index) => {
          if (!standardbuilders.includes(miniItem.id)) {
            setStandardBuilders(previous => [...previous, miniItem.id]);
            setAllStandardBuilders(previous => [...previous, miniItem]);
            setOriginalDataOfStandard(previous => [...previous, miniItem]);
          }
        });
      }
    }
  };

  const handleUpdateItem = (type, item, index) => {
    if (type === 'comparison') {
      setComparisonItem(item);
      setShowComponent('editComparisonName');
      setComparisonTitle(item.group_name);
      setItemIndex(index);
    } else if (type === 'createCatalog') {
      setShowComponent('createCatalog');
    } else {
      setComparisonItem(item);
      setShowComponent('editStandardName');
      setComparisonTitle(item.group_name);
      setAddOnValue(item.add_on);
      setItemIndex(index);
    }
  };

  useEffect(() => {
    if (props.type === 'profile' && props.userData) {
      setBuilders([props.userData.data.id]);
      setInvoiceUsers([props.userData.data.id]);
    }
  }, []);

  const discardDraft = () => {
    var payload = {
      inserted_data: [
        {
          invoice_type: 'invoice',
          discard_draft: true,
        },
      ],
    };
    commonApi
      .send_invoice_estimate(payload)
      .then(res => {
        successMessage(res.message);
        setShowComponent('');
        setInvoiceDraft({});
        setInvoiceName('');
        setCatalogIds('');
        setSelectedProduct([]);
        setInVoiceData({
          name: '',
          subscripton: '0.00',
          subtotal: '0.00',
          tax: '0.00',
          discount: '0.00',
          shipping: '0.00',
          total: '0.00',
          payment_Schedule: '',
        });

        setNote('');

        const date = new Date();

        // date.setHours(12, 0, 0, 0);

        setInvoiceDate(date);

        const nextDay = new Date();
        nextDay.setDate(nextDay.getDate() + 1);
        // nextDay.setHours(12, 0, 0, 0);
        setDueDate(nextDay);

        setBuilders([]);
        setInvoiceUsers([]);
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handleReset = () => {
    setShowSearchProduct('');
    setOutOfStockItems([]);
    setUserSearchQry('');
    setUserDetail([]);
    setNoResults(false);
  };
  // useEffect(() => {
  //   if (selectedProduct.length === 0) {
  //     setInVoiceData({
  //       name: '',
  //       subscripton: '0.00',
  //       subtotal: '0.00',
  //       tax: '0.00',
  //       discount: '0.00',
  //       shipping: '0.00',
  //       total: '0.00',
  //       payment_Schedule: '',
  //     });
  //   }
  // }, [selectedProduct]);

  // console.log(selectedProduct, 'selectedProduct');

  // console.log(invoiceData, 'invoiceData');
  // console.log(dueDate, 'dueDate');

  // console.log(builders, 'builders');
  // console.log(comparisonBuilders, 'comparisonBuilders');

  // console.log(comparsionItem, 'comparsionItem');

  // console.log(dueDate, 'dueDate');

  // console.log(deleteCatalog, 'deleteCatalog');

  // console.log(deleteCatalogItem, 'deleteCatalogItem');
  // console.log(invoiceUsers, 'invoiceUsers');

  const handleDataChange = newData => {
    setSelectedProduct(newData);
  };
  //   const InvoiceItem = ({item,index,setSelectedProduct,handleDataChange,selectedProduct}) =>{

  //     const [moreOptions, setMoreOptions] = useState(false);

  //     const [width, setWidth] = useState(34);
  //     const [shippingWidth, setShippingW] = useState(34);
  //     const [discountWidth, setDiscountW] = useState(34);

  //     const taxRef = useRef(null);
  //     const discountRef = useRef(null);
  //     const shippingRef = useRef(null);

  //     useEffect(() => {
  //       if (!initialRender) {
  //         if (taxRef.current) {
  //           setWidth(taxRef.current.offsetWidth + 2);
  //         }
  //         if (discountRef.current) {
  //           setDiscountW(discountRef.current.offsetWidth + 2);
  //         }
  //         if (shippingRef.current) {
  //           setShippingW(shippingRef.current.offsetWidth + 2);
  //         }
  //       }
  //     }, [selectedProduct[index]]);

  //     const handleInputChange = e => {
  //       const {name, value} = e.target;

  //       if (parseFloat(value) > 1000000000000000) {
  //         return;
  //       }

  //       const regex = /^(?!0(?!\.))\d*(\.\d{0,2})?$/;
  //       // const regex = /^[1-9]\d*(\.\d{0,2})?$|^0\.\d{0,2}$/;
  //       // const regex = /^(?!0\d)\d*(\.\d{0,2})?$/;
  //       if (value === '' || regex.test(value) || value === '0') {
  //         if (name === 'discount') {
  //           const discountValue = parseFloat(value);
  //           const subtotalValue = parseFloat(item.subtotal);
  //           const taxValue = parseFloat(item.tax);
  //           const shippingValue = parseFloat(item.shipping);

  //           if (discountValue >= item.cost + item.taxes_fee + item.shipping_handling) {
  //             errorMessage('Discount cannot exceed total.');
  //             return;
  //           }
  //         }

  //         if(name ==='tax'){

  //           let newData = [...selectedProduct]

  //           console.log(newData,'newDatanewData')
  //           newData[index].taxes_fee = value
  //           handleDataChange(newData)
  //           // setSelectedProduct(newData)
  //         }else if(name ==='discount'){
  //           let newData = [...selectedProduct]
  //           newData[index].discount = value
  //           handleDataChange(newData)
  //           // setSelectedProduct(newData)
  //         } else if(name ==='shipping'){
  //           let newData = [...selectedProduct]
  //           newData[index].shipping_handling = value
  //           handleDataChange(newData)
  //           // setSelectedProduct(newData)
  //         }

  //         // console.log(newData[index],'newData[index]newData[index]')

  //         // setSelectedProduct()
  //         // setInVoiceData({...item, [name]: value === '' ? '' : value});
  //       }
  //     };

  //     const handleBlur = event => {
  //       const {name, value} = event.target;
  //       let parsedValue = parseFloat(value.trim());
  //       let formattedValue = isNaN(parsedValue) ? '0.00' : parsedValue.toFixed(2);

  //       if(name ==='tax'){

  //         let newData = [...selectedProduct]

  //         console.log(newData,'newDatanewData')
  //         newData[index].taxes_fee = formattedValue
  //         setSelectedProduct(newData)
  //       }else if(name ==='discount'){
  //         let newData = [...selectedProduct]
  //         newData[index].discount = formattedValue
  //         setSelectedProduct(newData)
  //       } else if(name ==='shipping'){
  //         let newData = [...selectedProduct]
  //         newData[index].shipping_handling = formattedValue
  //         setSelectedProduct(newData)
  //       }
  //       // setInVoiceData({...invoiceData, [name]: formattedValue});
  //     };

  //     useEffect(() => {
  //     console.log(selectedProduct,'selectedProduct')
  //     }, [selectedProduct])

  //     return(

  //       <>
  //       <div
  //         key={item.id + index}
  //         className="d-flex justify-content-between">
  //         <h1>{item.title}</h1>

  //         <span
  //           className=" as-link invoice_delete"
  //           onClick={() => {
  //             // removeProduct(item, index);
  //             setDeleteCatalog({
  //               item: item,
  //               index: index,
  //             });
  //             setShowComponent('deleteCatalog');
  //           }}>
  //           <i className="fa fa-trash-can" />
  //         </span>
  //       </div>

  //       <div className="template-flex">
  //         <span className="template-text">
  //           {item.duration
  //             ? formatMinutesTime(item.duration)
  //             : ''}
  //         </span>

  //         <span>
  //           <span className="template-text">
  //             {item.catalog_type === 'service'
  //               ? '(Subscription)'
  //               : '(Product)'}{' '}
  //           </span>
  //           {formatNumberWithTwoDecimals(item.cost)}
  //         </span>
  //       </div>

  //       {/* {selectedProduct.length > 0 && !moreOptions && (
  //                         <div
  //                           className="more-option template-text as-link fit_content"
  //                           onClick={() => {
  //                             setMoreOptions(!moreOptions);
  //                           }}>
  //                           More Options
  //                         </div>
  //                       )} */}

  //       {/* {moreOptions && ( */}
  //                             <>
  //                               {/* <div className="template-flex">
  //                                 <span>Subtotal</span>

  //                                 <span>
  //                                   $
  //                                   {formatNumberWithTwoDecimals(
  //                                     invoiceData.subtotal,
  //                                   )}
  //                                 </span>
  //                               </div> */}

  //                               <div className="template-flex">
  //                                 <span>Taxes and Fees</span>

  //                                 <div className="d-flex ">
  //                                   <span className="invoice_cost f_600">$</span>
  //                                   <span id="invoice_cost_span" ref={taxRef}>
  //                                     {item.tax}
  //                                   </span>

  //                                   <Form.Control
  //                                     name="tax"
  //                                     type="number"
  //                                     className="invoice_input_field template-text f_600"
  //                                     min="0.00"
  //                                     step="0.01"
  //                                     style={{width}}
  //                                     // style={{ width: `${cost.length}ch` }}
  //                                     value={item.taxes_fee}
  //                                     onChange={handleInputChange}
  //                                     // onBlur={handleBlur}
  //                                   />
  //                                 </div>
  //                               </div>

  //                               <div className="template-flex">
  //                                 <span>Discount</span>

  //                                 <div className="d-flex ">
  //                                   <span className="invoice_cost f_600">$</span>
  //                                   <span
  //                                     id="invoice_cost_span"
  //                                     ref={discountRef}>
  //                                     {item.discount}
  //                                   </span>

  //                                   <Form.Control
  //                                     name="discount"
  //                                     type="number"
  //                                     className="invoice_input_field template-text f_600"
  //                                     min="0.00"
  //                                     step="0.01"
  //                                     style={{width: discountWidth}}
  //                                     // style={{ width: `${cost.length}ch` }}
  //                                     value={item.discount}
  //                                     onChange={handleInputChange}
  //                                     // onBlur={handleBlur}
  //                                   />
  //                                 </div>
  //                               </div>

  //                               <div className="template-flex">
  //                                 <span>Shipping & handling</span>

  //                                 <div className="d-flex ">
  //                                   <span className="invoice_cost f_600">$</span>
  //                                   <span
  //                                     id="invoice_cost_span"
  //                                     ref={shippingRef}>
  //                                     {item.shipping_handling}
  //                                   </span>

  //                                   <Form.Control
  //                                     name="shipping"
  //                                     type="number"
  //                                     className="invoice_input_field template-text f_600"
  //                                     min="0.00"
  //                                     step="0.01"
  //                                     style={{width: shippingWidth}}
  //                                     // style={{ width: `${cost.length}ch` }}
  //                                     value={item.shipping_handling}
  //                                     onChange={handleInputChange}
  //                                     // onBlur={handleBlur}
  //                                   />
  //                                 </div>
  //                               </div>
  //                             </>
  //                           {/* )} */}

  // {/* {selectedProduct.length > 0 && moreOptions && (
  //                         <div
  //                           className="more-option template-text as-link mb-3"
  //                           onClick={() => {
  //                             setMoreOptions(!moreOptions);
  //                           }}>
  //                           Less Options
  //                         </div>
  //                       )} */}
  //     </>
  //     )

  //   }


  const renderHeader = () => {
  
    return (
      <>
        
          <img src={RoiLogo} alt="" />
        
      </>
    );
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
        }}
        marginTop={props.marginTop ? true : false}
        renderData={renderHeader}
    showBlackHeader={true}
    headerPadding={'24px 20px'}
    lastDivStyle={{borderRadius: '35px', background: '#f7f6f2'}}
    firstDivStyle={{
      padding: 0,
      background:
        'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)',
    }}>
        <div className="serach-filter-wrapper create-sessionEvents pt-3">
          <div className="create-ticket-form  primary_panel_content">
            <div className="custom_tabs d-flex align-items-center justify-content-center fixed-width-inputs black-head-form-pad-left fix-width-forms w-100">
              <div className="create-ticket-tabs mt-0">
                <CustomTabs
                  darkmode
                  tabs={ticketTabs}
                  active={mainTab}
                  onSelect={item => {
                    setMainTab(item);
                  }}
                />
              </div>
            </div>

            {mainTab === labels.sessions_type_general[selected_lang] && (
              <Fragment>
                <div className="messages-header mb-2 fixed-width-inputs black-head-form-pad-left  fix-width-forms">
                  {/* <div className="event-tabs dark-tabs"> */}
                  <div className="event-tabs tabs-style-2 mb-2">
                    {' '}
                    {/* Merging */}
                    <ul>
                      <li>
                        <Button
                          className={tabType === 'invoices' ? 'active' : ''}
                          onClick={() => {
                            // setGalleryType('chats');
                            setTabType('invoices');
                            // setShowSpinner(true);
                          }}>
                          {labels.technician_payment_invoices[selected_lang]}
                        </Button>
                      </li>
                      <li>
                        <Button
                          className={tabType === 'estimates' ? 'active' : ''}
                          onClick={() => {
                            // setGalleryType('subInvites');
                            // setshowLoader(true)
                            // setShowSpinner(true);
                            setShowLoader(true);
                            handleReset();
                            handleInvoiceDraft(invoiceDraft);
                            handleTemplates();
                            setTabType('estimates');
                            // amplitudeEvent('CHECK_CHAT_INVITES');
                          }}>
                          {labels.technician_payment_estimates[selected_lang]}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  {props.type !== 'template' && (
                    <div className="search-tabs-icons">
                      <img
                        className="template-img as-link"
                        src={Templates}
                        alt=""
                        onClick={() => {
                          if (tabType === 'invoices') {
                            setShowComponent('templateList');
                          } else {
                            if (subType === 'comparison') {
                              setShowComponent('comaprisonTemplate');
                            } else {
                              setShowComponent('stnadardTemplate');
                            }
                          }
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className=" create-template refferal-list-wrapper">
                  {showLoader && (
                    <div className="">
                      <LoaderSpinner />
                    </div>
                  )}
                  <Scrollbars autoHide>
                    <div className=" create-template-content fixed-width-inputs black-head-form-pad-left fix-width-forms  ">
                      {tabType === 'invoices' && (
                        <div
                          className={`d-flex justify-content-between ${
                            tabType === 'invoices' ? 'estimate_contact' : ''
                          }`}>
                          {/* {selectedProduct.length > 0 && ( */}
                          <div>
                            {Object.keys(invoiceDraft).length > 0 && (
                              <div
                                className="template-text fit_content  text-custom-13 as-link"
                                onClick={() => {
                                  if (Object.keys(invoiceDraft).length > 0) {
                                    setShowComponent('discardDraft');
                                  }
                                }}>
                                Discard Draft
                              </div>
                            )}
                          </div>
                          {/* )} */}
                          {(builders.length > 0 ||
                            selectedProduct.length > 0) && (
                            <div
                              className="template-text pb-1 as-link fit_content  text-custom-13"
                              onClick={() => {
                                setAddUser('addUser');
                              }}>
                              {builders && builders.length > 0
                                ? builders.length > 1
                                  ? `${builders.length} Contacts Selected`
                                  : '1 Contact Selected'
                                : 'Select Contact'}
                            </div>
                          )}
                        </div>
                      )}

                      {tabType === 'invoices' && (
                        <div className="invoice">
                          <div className="roi-form-input">
                            <Form.Control
                              type="text"
                              value={invoiceName}
                              pattern="^\S.*$"
                              required
                              placeholder="Title"
                              onChange={e => {
                                setInvoiceName(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Title is required.
                            </Form.Control.Feedback>
                          </div>

                          <div className="roi-form-input">
                            <Form.Control
                              type="text"
                              // value={invoiceService}
                              pattern="^\S.*$"
                              // required
                              placeholder="Search for contact"
                              value={userSearchQry}
                              ref={userInputRef}
                              onChange={e => {
                                get_users_for_invoices(e.target.value);
                                setUserSearchQry(e.target.value);
                              }}
                            />
                            {userSearchQry && userDetail.length > 0 ? (
                              <ul
                                className="custom-suggestions invoices_suggestion"
                                ref={userDropdownRef}>
                                {userDetail.map(suggestion => (
                                  <li
                                    className="as-link"
                                    key={'suggestion' + suggestion.id}
                                    onClick={() => {
                                      handleUsers(suggestion);
                                    }}>
                                    {fixName(
                                      suggestion.first_name,
                                      suggestion.last_name,
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              noResults &&
                              userSearchQry && (
                                <div className="text-center mt-3">
                                  No user found.
                                </div>
                              )
                            )}
                          </div>

                          <div className="roi-form-input">
                            <Form.Control
                              type="text"
                              // value={invoiceService}
                              pattern="^\S.*$"
                              // required
                              placeholder="Add catalog"
                              value={showSearchProduct}
                              ref={inputRef}
                              onChange={e => {
                                setShowSearchProduct(e.target.value);
                                get_catalogs_for_invoices(e.target.value);
                              }}
                            />
                            {
                              showSearchProduct && (
                                <ul
                                  className="custom-suggestions invoices_suggestion"
                                  ref={dropdownRef}>
                                  {checkPermesionOfCreate && (
                                    <li
                                      className="as-link"
                                      key={'Add-offerings'}
                                      onClick={() => {
                                        setShowComponent('createCatalog');
                                      }}>
                                      Add offerings
                                    </li>
                                  )}
                                  {productDetail.length > 0 &&
                                    productDetail.map((suggestion, index) => (
                                      <li
                                        className="as-link"
                                        key={index + '-' + suggestion.id}
                                        onClick={() => {
                                          objectValues(suggestion);
                                        }}>
                                        {capitalizeFirst(suggestion.title)}
                                      </li>
                                    ))}
                                </ul>
                              )
                              // : showSearchProduct  &&(
                              //   <ul className="custom-suggestions invoices_suggestion">
                              //   <li className="as-link">{'Not found'}</li>
                              //   </ul>
                              // )
                            }
                          </div>
                          {productDetail.length === 0 && (
                            <NorecordFound
                              noRecordData={NoOfferingsFound}
                              buttonPermesion={checkPermesionOfCreate}
                              ButtonAction={() => {
                                setShowComponent('createCatalog');
                              }}
                            />
                          )}

                          {selectedProduct.length > 0 ? (
                            <div>
                              {selectedProduct.map((item, index) => (
                                <InvoiceProduct
                                  item={item}
                                  ItemIndex={index}
                                  setSelectedProduct={setSelectedProduct}
                                  handleDataChange={handleDataChange}
                                  selectedProduct={selectedProduct}
                                  setShowComponent={setShowComponent}
                                  setDeleteCatalog={setDeleteCatalog}
                                  showErrorFromApi={showErrorFromApi}
                                  showOutOfStockError={showOutOfStockError}
                                  outOfStockItems={outOfStockItems}
                                  expensesList={expensesList}
                                />
                              ))}
                              {/* <h1>Consulting</h1> */}
                              {/* <div className="template-flex">
                              <span className="template-text">2 Hours</span>

                              <span>
                                <span className="template-text">
                                  (Subscription){' '}
                                </span>
                                ${invoiceData.subscripton}
                              </span>
                            </div> */}

                              {/* {moreOptions && (
                            <>
                              <div className="template-flex">
                                <span>Subtotal</span>

                                <span>
                                  $
                                  {formatNumberWithTwoDecimals(
                                    invoiceData.subtotal,
                                  )}
                                </span>
                              </div>

                              <div className="template-flex">
                                <span>Taxes and Fees</span>

                                <div className="d-flex ">
                                  <span className="invoice_cost f_600">$</span>
                                  <span id="invoice_cost_span" ref={taxRef}>
                                    {invoiceData.tax}
                                  </span>

                                  <Form.Control
                                    name="tax"
                                    type="number"
                                    className="invoice_input_field template-text f_600"
                                    min="0.00"
                                    step="0.01"
                                    style={{width}}
                                    // style={{ width: `${cost.length}ch` }}
                                    value={invoiceData.tax}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>

                              <div className="template-flex">
                                <span>Discount</span>

                                <div className="d-flex ">
                                  <span className="invoice_cost f_600">$</span>
                                  <span
                                    id="invoice_cost_span"
                                    ref={discountRef}>
                                    {invoiceData.discount}
                                  </span>

                                  <Form.Control
                                    name="discount"
                                    type="number"
                                    className="invoice_input_field template-text f_600"
                                    min="0.00"
                                    step="0.01"
                                    style={{width: discountWidth}}
                                    // style={{ width: `${cost.length}ch` }}
                                    value={invoiceData.discount}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>

                              <div className="template-flex">
                                <span>Shipping & handling</span>

                                <div className="d-flex ">
                                  <span className="invoice_cost f_600">$</span>
                                  <span
                                    id="invoice_cost_span"
                                    ref={shippingRef}>
                                    {invoiceData.shipping}
                                  </span>

                                  <Form.Control
                                    name="shipping"
                                    type="number"
                                    className="invoice_input_field template-text f_600"
                                    min="0.00"
                                    step="0.01"
                                    style={{width: shippingWidth}}
                                    // style={{ width: `${cost.length}ch` }}
                                    value={invoiceData.shipping}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </>
                          )} */}

                              <div className="template-flex-total">
                                <span className="template-text">Total</span>

                                <span>${calculateTotal(selectedProduct)}</span>
                              </div>

                              <div className="template-flex payment-schedule">
                                <span>Payment Schedule</span>

                                <span className="template-text">
                                  {selectedProduct.length > 0 &&
                                  checkSubscriptionType(selectedProduct) ===
                                    'monthly'
                                    ? 'Recurring Monthly'
                                    : checkSubscriptionType(selectedProduct)}
                                </span>
                              </div>
                            </div>
                          ) : (
                            // <div
                            //   className="d-flex justify-content-center align-items-center"
                            //   style={{height: '20vh'}}>
                            //   Add Product or Service
                            // </div>
                            <></>
                          )}

                          {selectedProduct.length > 0 && !moreOptions && (
                            <div
                              className="more-option template-text as-link fit_content"
                              onClick={() => {
                                setMoreOptions(!moreOptions);
                              }}>
                              More Options
                            </div>
                          )}

                          {selectedProduct.length > 0 && moreOptions && (
                            <div
                              className="more-option template-text as-link mb-3"
                              onClick={() => {
                                setMoreOptions(!moreOptions);
                              }}>
                              Less Options
                            </div>
                          )}

                          {moreOptions && selectedProduct.length > 0 && (
                            <>
                              <Form.Group className="fieldset mt-2">
                                <Form.Control
                                  as="textarea"
                                  required
                                  rows={4}
                                  // pattern="^\S.*$"
                                  placeholder="Note to Cutomer"
                                  value={note}
                                  onChange={e => {
                                    setNote(e.target.value);
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  Feedback is required.
                                </Form.Control.Feedback>
                              </Form.Group>

                              <div className="template-flex payment-schedule">
                                <span>Invoice Date And Time</span>

                                <div onClick={handleClickD}>
                                  {invoiceDate === '' ? (
                                    <span className="template-text">
                                      {' '}
                                      <i className="fa-solid fa-calendar-days" />
                                    </span>
                                  ) : (
                                    <span className="template-text as-link">
                                      {Moment(invoiceDate)
                                        .format('MMM D, YYYY h:mm A')
                                        .toString()}
                                    </span>
                                  )}
                                </div>

                                <div className="invoice_calender-wrapper">
                                  {/* {isOpen && (
                                    <DatePicker
                                      selected={invoiceDate}
                                      onChange={handleChange}
                                      showTimeSelect
                                      timeIntervals={1}
                                      onClickOutside={() => {
                                        handleOnClickOutside();
                                      }}
                                      // dateFormat="MMM D , yyyy h:mm aa"
                                      // timeFormat="HH:mm"
                                      minDate={new Date()}
                                      maxDate={dueDate}
                                      inline
                                    />
                                  )} */}
                {isOpen && (
                    <Calender
                        minDate={new Date()}
                        maxDate={dueDate}
                        setSelectedDate={handleChange}
                        selectedDate={invoiceDate ? new Date(invoiceDate) : ''} 
                        showDate={true} 
                        showTime={true}
                        type='invoice'
                        // calendarRef={calendarRef}
                        isOpenCalendar={isOpen}
                        />
                      )}

                                </div>
                              </div>

                              <div className="template-flex payment-schedule">
                                <span>Due Date And Time</span>

                                <div onClick={handleClickE}>
                                  {dueDate === '' ? (
                                    <span className="template-text">
                                      {' '}
                                      <i className="fa-solid fa-calendar-days" />
                                    </span>
                                  ) : (
                                    <span className="template-text as-link">
                                      {Moment(dueDate)
                                        .format('MMM D, YYYY h:mm A')
                                        .toString()}
                                    </span>
                                  )}
                                </div>

                                <div className="invoice_calender-wrapper">
                                  {/* {endIsOpen && (
                                    <DatePicker
                                      selected={dueDate}
                                      onChange={handleChange}
                                      showTimeSelect
                                      timeIntervals={1}
                                      onClickOutside={() => {
                                        handleOnClickOutside();
                                      }}
                                      minDate={new Date()}
                                      inline
                                    />
                                  )} */}

                    {endIsOpen && (
                    <Calender
                        minDate={new Date()}
                        maxDate={getMaxDateFromToday}
                        setSelectedDate={handleChange}
                        selectedDate={dueDate  ? new Date(dueDate) : ''} 
                        showDate={true} 
                        showTime={true}
                         type='invoice'
                        // calendarRef={calendarRef}
                        isOpenCalendar={endIsOpen}
                        />
                      )}
                        
                                </div>
                              </div>
                            </>
                          )}

                          {selectedProduct.length > 0 && (
                            <div className="template-btns">
                              <div>
                                <Button
                                  className="new-btn btn-green "
                                  onClick={() => {
                                    handleSubmit('');
                                  }}>
                                  Send Invoice
                                </Button>
                              </div>

                              <button
                                style={{
                                  color: '#68b245',
                                }}
                                className="as-link "
                                onClick={() => {
                                  handleSubmit('template');
                                }}>
                                Save As Template
                              </button>

                              <button
                                style={{
                                  color: '#68b245',
                                }}
                                className="as-link "
                                onClick={() => {
                                  handleSubmit('draft');
                                }}>
                                Save as Draft
                              </button>
                            </div>
                          )}

                          {/* <div>
      <TimePicker onChange={setTime} value={time} />
    </div> */}
                        </div>
                      )}

                      {tabType === 'estimates' && (
                        <InvoiceEstimate
                          setShowComponent={setShowComponent}
                          comparisonList={comparisonList}
                          setComparisonList={setComparisonList}
                          addUser={addUser}
                          setAddUser={setAddUser}
                          comparsionUsers={comparisonBuilders}
                          standardUsers={standardbuilders}
                          setComparsionUsers={setComparisonBuilders}
                          setStandardUsers={setStandardBuilders}
                          setCompUsers={setCompUsers}
                          setStandUsers={setStandUsers}
                          onClose={() => {
                            props.onClose();
                          }}
                          titleRef={comparisonModalRef}
                          standardRef={standardModalRef}
                          setComparisonTitle={setComparisonTitle}
                          subType={subType}
                          setSubType={setSubType}
                          templateRef={templateRef}
                          setStandardItem={setStandardItem}
                          setComparisonItem={setComparisonItem}
                          handleUpdateItem={handleUpdateItem}
                          setShowLoader={setShowLoader}
                          getEstimateTemplates={
                            props.getTemplates
                              ? props.getTemplates
                              : getEstimateTemplates
                          }
                          deleteCatalogItem={deleteCatalogItem}
                          setDeleteCatalogItem={setDeleteCatalogItem}
                          estimateTemplateItem={props.estimateTemplate}
                          type={props.subType}
                          getDraft={props.getDraft}
                          checkPermesionOfCreate={checkPermesionOfCreate}
                          userDetail={userDetail}
                          setUserDetail={setUserDetail}
                          userSearchQry={userSearchQry}
                          setUserSearchQry={setUserSearchQry}
                          handleComparisonUsers={handleComparisonUsers}
                          handleStandardUsers={handleStandardUsers}
                          dropdownRef={dropdownRef}
                          expensesList={expensesList}
                        />
                      )}
                    </div>
                  </Scrollbars>
                </div>
              </Fragment>
            )}

            {mainTab === labels.invoice_expenses[selected_lang] && (
              <InvoiceExpense
                expensesList={expensesList}
                setExpensesList={setExpensesList}
                setShowComponent={setShowComponent}
                showComponent={showComponent}
                expenseItem={expenseItem}
                setExpenseItem={setExpenseItem}
              />
            )}
          </div>
        </div>
      </SlidingPanel>

      {addUser === 'addUser' && (
        <SlidingPanel
          hidePanel={() => {
            if (invoiceUsers.length !== builders.length) {
              setBuilders(invoiceUsers);
            }
            if (inviteQry !== '') {
              setInviteFollowers(followersList);
              setInviteQry('');
            }
            setAddUser('');
            setFollowers([]);
          }}>
          <div className="primary_panel_content">
            <div className="refferal-list-wrapper ">
              {inviteLoader && (
                <div className="invite_loader_hieght">
                  <LoaderSpinner />
                </div>
              )}
              <Scrollbars>
                <div
                  className=" fixed-width-inputs black-head-form-pad-left fix-width-forms"
                  style={{paddingBottom: '40px'}}>
                  <Invite
                    type={'refer'}
                    component={'invoice'}
                    moderator={builders}
                    setModerator={setBuilders}
                    selectedModData={allBuilders}
                    setSelectedModData={setAllBuilders}
                    originalDataOfMod={originalDataOfMod}
                    setOriginalDataOfMod={setOriginalDataOfMod}
                    onClose={() => {
                      setAddUser('');
                      if (builders.length > 0) {
                        setInvoiceUsers(builders);
                      }
                    }}
                    onClick={() => {
                      setShowComponent('inviteUser');
                      setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    handleCircle={handleCircle}
                    setShowComponent={setShowComponent}
                    hitApiAddedUser={hitApiAddedUser}
                    setOriginalFolowers={setFollowers}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
            </div>
            {inviteFollowers.length > 0 && (
              <div className="filter-btn-wrapper pt-2  fixed-width-inputs black-head-form-pad-left fix-width-forms bg_transparent bottom-0">
                <div className="invite-btn text-center ">
                  <Button
                    className="btn-dark"
                    type="button"
                    onClick={() => {
                      setAddUser('');
                      if (builders.length > 0) {
                        setInvoiceUsers(builders);
                      }
                      setFollowers([]);

                      if (outOfStockItems.length > 0) {
                        const checkProductAvailablitiy = checkAvailablitiy(
                          'invoice',
                          selectedProduct,
                          builders,
                          [],
                        );

                        setOutOfStockItems(
                          checkProductAvailablitiy.length > 0
                            ? checkProductAvailablitiy
                            : [],
                        );
                      }
                    }}>
                    Select
                  </Button>
                </div>
              </div>
            )}
          </div>
        </SlidingPanel>
      )}

      {addUser === 'comparisonUsers' && (
        <SlidingPanel
          hidePanel={() => {
            setAddUser('');
            if (comparisonBuilders.length !== compUsers.length) {
              setComparisonBuilders(compUsers);
            }
            if (inviteQry !== '') {
              setInviteFollowers(followersList);
              setInviteQry('');
            }
            setFollowers([]);
          }}>
          <div className="primary_panel_content">
            <div className="refferal-list-wrapper">
              <Scrollbars>
                <div
                  className="fixed-width-inputs black-head-form-pad-left fix-width-forms"
                  style={{paddingBottom: '40px'}}>
                  <Invite
                    type={'refer'}
                    component={'invoice'}
                    moderator={comparisonBuilders}
                    setModerator={setComparisonBuilders}
                    selectedModData={allComparisonBuilders}
                    setSelectedModData={setAllComparisonBuilders}
                    originalDataOfMod={originalDataOfComparison}
                    setOriginalDataOfMod={setOriginalDataOfComparsion}
                    onClose={() => {
                      setAddUser('');
                      if (comparisonBuilders.length > 0) {
                        setCompUsers(comparisonBuilders);
                      }
                    }}
                    onClick={() => {
                      setShowComponent('inviteUser');
                      setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    handleCircle={handleCircle}
                    setOriginalFolowers={setFollowers}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
            </div>
            {inviteFollowers.length > 0 && (
              <div className="filter-btn-wrapper pt-2 fixed-width-inputs black-head-form-pad-left fix-width-forms bg_transparent bottom-0">
                <div className="invite-btn text-center ">
                  <Button
                    className="btn-dark"
                    type="button"
                    onClick={() => {
                      setAddUser('');
                      if (comparisonBuilders.length > 0) {
                        setCompUsers(comparisonBuilders);
                      }
                      setFollowers([]);

                      comparisonModalRef.current.checkStockAvailabilty();
                    }}>
                    Select
                  </Button>
                </div>
              </div>
            )}
          </div>
        </SlidingPanel>
      )}

      {addUser === 'standardUsers' && (
        <SlidingPanel
          hidePanel={() => {
            setAddUser('');
            if (standardbuilders.length !== standUsers.length) {
              setStandardBuilders(standUsers);
            }
            if (inviteQry !== '') {
              setInviteFollowers(followersList);
              setInviteQry('');
            }
            setFollowers([]);
          }}>
          <div className="primary_panel_content">
            <div className="refferal-list-wrapper">
              <Scrollbars>
                <div className="fixed-width-inputs black-head-form-pad-left fix-width-forms">
                  <Invite
                    type={'refer'}
                    component={'invoice'}
                    moderator={standardbuilders}
                    setModerator={setStandardBuilders}
                    selectedModData={allStandardBuilders}
                    setSelectedModData={setAllStandardBuilders}
                    originalDataOfMod={originalDataOfStandard}
                    setOriginalDataOfMod={setOriginalDataOfStandard}
                    onClose={() => {
                      setAddUser('');
                      if (standardbuilders.length > 0) {
                        setStandUsers(standardbuilders);
                      }
                    }}
                    onClick={() => {
                      setShowComponent('inviteUser');
                      setTimeout(() => {
                        if (contact.length === 0 && inviteQry !== '') {
                          setInviteFollowers(followersList);
                          setInviteQry('');
                        }
                      }, 1000);
                    }}
                    contact={contact}
                    setContact={setContact}
                    forApiHit={showComponent}
                    handleCircle={handleCircle}
                    setOriginalFolowers={setFollowers}
                    showLoader={inviteLoader}
                    setshowLoader={setInviteLoader}
                    followers={inviteFollowers}
                    setFollowers={setInviteFollowers}
                    setFollowersList={setFollowersList}
                    followersList={followersList}
                    setSearchQry={setInviteQry}
                    searchQry={inviteQry}
                  />
                </div>
              </Scrollbars>
            </div>

            {inviteFollowers.length > 0 && (
              <div className="filter-btn-wrapper pt-2 fixed-width-inputs black-head-form-pad-left fix-width-forms bg_transparent bottom-0">
                <div className="invite-btn text-center ">
                  <Button
                    className="btn-dark"
                    type="button"
                    onClick={() => {
                      setAddUser('');
                      if (standardbuilders.length > 0) {
                        setStandUsers(standardbuilders);
                      }
                      setFollowers([]);
                      standardModalRef.current.checkStockAvailabilty();
                    }}>
                    Select
                  </Button>
                </div>
              </div>
            )}
          </div>
        </SlidingPanel>
      )}

      {showComponent === 'createCatalog' && (
        <CreateCatalog
          onClose={() => {
            setShowComponent('');
          }}
          onCloseWithApiHit={() => {
            setShowComponent('');
          }}
        />
      )}
      {showComponent === 'CircleList' && (
        <CircleListBuilders
          onClose={() => {
            setShowComponent('');
          }}
          hide={true}
          CircleUserListData={CircleUserListData}
        />
      )}
      {showComponent === 'inviteUser' && (
        <SendInvitation
          setContact={setContact}
          setHitApiAddedUser={setHitApiAddedUser}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'comparisonModal' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent('');
            setComparisonTitle('');
            setTitleValidation(false);
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label
                    className="w-auto f_600_h"
                    controlid="validationCustom01">
                    Name
                  </Form.Label>
                </div>

                <Form.Control
                  required
                  value={comparisonTitle}
                  as="textarea"
                  rows={6}
                  type="text"
                  placeholder="Add Comparison Name... "
                  onChange={e => {
                    setComparisonTitle(e.target.value);
                  }}
                />
                {titleValidation && comparisonTitle === '' && (
                  <div className="text-danger textforerror text-left">
                    Comparison Name required
                  </div>
                )}
                {/* <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback> */}
              </div>

              {/* <Button
                className="no-thanks"
                onClick={() => {
                  if (comparisonTitle === '') {
                    setTitleValidation(true);
                  } else {
                    comparisonModalRef.current.addComparison(comparisonTitle);
                    setTitleValidation(false);
                  }
                }}>
                Add
              </Button> */}

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  className="py-2 px-5"
                  onClick={() => {
                    if (comparisonTitle === '') {
                      setTitleValidation(true);
                    } else {
                      comparisonModalRef.current.addComparison(comparisonTitle);
                      setTitleValidation(false);
                    }
                  }}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'standardModal' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent('');
            setComparisonTitle('');
            setAddOnValue(RadioButtonName[0]);
            setTitleValidation(false);
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label
                    className="w-auto f_600_h"
                    controlid="validationCustom01">
                    Name
                  </Form.Label>
                </div>

                <Form.Control
                  required
                  value={comparisonTitle}
                  as="textarea"
                  rows={6}
                  type="text"
                  placeholder="Add Group Name... "
                  onChange={e => {
                    setComparisonTitle(e.target.value);
                  }}
                />

                {titleValidation && comparisonTitle === '' && (
                  <div className="text-danger textforerror text-left">
                    Group Name required
                  </div>
                )}
                {/* <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback> */}
              </div>

              <Form.Label
                className="w-auto f_600_h"
                controlid="validationCustom01">
                Add On Option
              </Form.Label>
              <div className="d-flex gap-3">
                {Object.entries(RadioButtonName).length > 0 &&
                  RadioButtonName.map((item, index) => (
                    <Form.Check
                      type="radio"
                      // name="radioGroup"
                      label={item}
                      id={item}
                      value={item}
                      checked={addOnValue === item}
                      onChange={e => {
                        setAddOnValue(e.target.value);
                      }}
                    />
                  ))}
              </div>

              {/* <Button
                className="no-thanks"
                onClick={() => {
                  if (comparisonTitle === '') {
                    setTitleValidation(true);
                  } else {
                    standardModalRef.current.addStandard(
                      comparisonTitle,
                      addOnValue,
                    );
                    setTitleValidation(false);
                    setAddOnValue(RadioButtonName[0]);
                  }
                }}>
                Add
              </Button> */}

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  className="py-2 px-5"
                  onClick={() => {
                    if (comparisonTitle === '') {
                      setTitleValidation(true);
                    } else {
                      standardModalRef.current.addStandard(
                        comparisonTitle,
                        addOnValue,
                      );
                      setTitleValidation(false);
                      setAddOnValue(RadioButtonName[0]);
                    }
                  }}>
                  Add
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'editComparisonName' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent('');
            setComparisonItem({});
            setComparisonTitle('');
            setItemIndex(0);
            setTitleValidation(false);
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label
                    className="w-auto f_600_h"
                    controlid="validationCustom01">
                    Name
                  </Form.Label>

                  {/* <span
                    className="poc_delete mr-2 as-link"
                    onClick={() => {
                      comparisonModalRef.current.deleteComparison(
                        comparsionItem,
                        itemIndex,
                      );

                      setComparisonItem({});
                      setItemIndex(0);
                    }}>
                    <i className="fa-solid fa-trash-can" />
                  </span> */}
                </div>

                <Form.Control
                  required
                  value={comparisonTitle}
                  as="textarea"
                  rows={6}
                  type="text"
                  placeholder="Add Comparison Name... "
                  onChange={e => {
                    setComparisonTitle(e.target.value);
                  }}
                />
                {titleValidation && comparisonTitle === '' && (
                  <div className="text-danger textforerror text-left">
                    Comparison Name required
                  </div>
                )}
                {/* <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback> */}
              </div>

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  className="px-5"
                  onClick={() => {
                    if (comparisonTitle === '') {
                      setTitleValidation(true);
                    } else {
                      comparisonModalRef.current.updateComparsion(
                        comparisonTitle,
                        comparsionItem,
                        itemIndex,
                      );
                      setComparisonItem({});
                      setItemIndex(0);
                      setTitleValidation(false);
                    }
                  }}>
                  Update
                </Button>
              </div>

              <Button
                className="no-thanks m-0"
                onClick={() => {
                  // comparisonModalRef.current.deleteComparison(
                  //   comparsionItem,
                  //   itemIndex,
                  // );

                  // setComparisonItem({});
                  // setItemIndex(0);
                  setShowModel('delete_confirmation')
                }}>
                Delete
              </Button>

              {/* <div className="text-center">
                <span
                  className="template_text pb-3 as-link d-block "
                  onClick={() => {
                    comparisonModalRef.current.deleteComparison(
                      comparsionItem,
                      itemIndex,
                    );

                    setComparisonItem({});
                    setItemIndex(0);
                  }}>
                  Delete
                </span>
              </div> */}
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'editStandardName' && (
        <CustomModal
          size="small"
          onClose={() => {
            setShowComponent('');
            setComparisonItem({});
            setComparisonTitle('');
            setItemIndex(0);
            setAddOnValue(RadioButtonName[0]);
            setTitleValidation(false);
          }}
          className="availability-modal">
          <div
            className="new-session-modal-wrapper text-center  "
            style={{width: '-webkit-fill-available'}}>
            <div className="new-session-modal estimate_name_modal">
              <div className="fieldset">
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Label
                    className="w-auto f_600_h"
                    controlid="validationCustom01">
                    Name
                  </Form.Label>

                  {/* <span
                    className="poc_delete mr-2 as-link"
                    onClick={() => {
                      standardModalRef.current.deleteStandard(
                        comparsionItem,
                        itemIndex,
                      );

                      setComparisonItem({});
                      setAddOnValue(RadioButtonName[0]);
                      setItemIndex(0);
                    }}>
                    <i className="fa-solid fa-trash-can" />
                  </span> */}
                </div>

                <Form.Control
                  required
                  value={comparisonTitle}
                  as="textarea"
                  rows={6}
                  type="text"
                  placeholder="Add Group Name... "
                  onChange={e => {
                    setComparisonTitle(e.target.value);
                  }}
                />

                {titleValidation && comparisonTitle === '' && (
                  <div className="text-danger textforerror text-left">
                    Group Name required
                  </div>
                )}
                {/* <Form.Control.Feedback type="invalid">
                  Title is required.
                </Form.Control.Feedback> */}
              </div>

              <Form.Label
                className="w-auto f_600_h"
                controlid="validationCustom01">
                Add On Option
              </Form.Label>
              <div className="d-flex gap-3">
                {Object.entries(RadioButtonName).length > 0 &&
                  RadioButtonName.map((item, index) => (
                    <Form.Check
                      type="radio"
                      // name="radioGroup"
                      label={item}
                      id={item}
                      value={item}
                      checked={addOnValue === item}
                      onChange={e => {
                        setAddOnValue(e.target.value);
                      }}
                    />
                  ))}
              </div>

              {/* <Button
                className="no-thanks"
                onClick={() => {
                  if (comparisonTitle === '') {
                    setTitleValidation(true);
                  } else {
                    standardModalRef.current.updateStandard(
                      comparisonTitle,
                      comparsionItem,
                      addOnValue,
                      itemIndex,
                    );
                    setAddOnValue(RadioButtonName[0]);
                    setComparisonItem({});
                    setItemIndex(0);
                    setTitleValidation(false);
                  }
                }}>
                Update
              </Button> */}

              <div className="invite-btns  p-0 pt-3 text-center">
                <Button
                  className=" px-5"
                  onClick={() => {
                    if (comparisonTitle === '') {
                      setTitleValidation(true);
                    } else {
                      standardModalRef.current.updateStandard(
                        comparisonTitle,
                        comparsionItem,
                        addOnValue,
                        itemIndex,
                      );
                      setAddOnValue(RadioButtonName[0]);
                      setComparisonItem({});
                      setItemIndex(0);
                      setTitleValidation(false);
                    }
                  }}>
                  Update
                </Button>
              </div>

              <Button
                className="no-thanks m-0"
                onClick={() => {
                  // standardModalRef.current.deleteStandard(
                  //   comparsionItem,
                  //   itemIndex,
                  // );

                  // setComparisonItem({});
                  // setAddOnValue(RadioButtonName[0]);
                  // setItemIndex(0);
                  setShowModel('delete_confirmation')

                }}
                
                >
                Delete
              </Button>
              {/* <div className="text-center">
                <span
                  className="template_text pb-3 as-link d-block "
                  onClick={() => {
                    standardModalRef.current.deleteStandard(
                      comparsionItem,
                      itemIndex,
                    );

                    setComparisonItem({});
                    setAddOnValue(RadioButtonName[0]);
                    setItemIndex(0);
                  }}>
                  Delete
                </span>
              </div> */}
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'templateList' && (
        <InvoiceTemplates
          templatesList={templatesList}
          templateItem={templateItem}
          setTemplateItem={setTemplateItem}
          setShowInvoice={setShowInvoice}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'comaprisonTemplate' && (
        <InvoiceTemplates
          type={'comparison'}
          templatesList={comparisonTemplates}
          handleItem={item => {
            // templateRef.current.addTemplateItem(item);
            setComparisonTemplateItem(item);
            setShowInvoice('showComparisonTemplate');
          }}
          setShowInvoice={setShowInvoice}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'stnadardTemplate' && (
        <InvoiceTemplates
          type={'standard'}
          templatesList={standardTemplates}
          handleItem={item => {
            // templateRef.current.addTemplateItem(item);
            setComparisonTemplateItem(item);
            setShowInvoice('showStandardTemplate');
          }}
          onClose={() => {
            setShowComponent('');
          }}
        />
      )}

      {showComponent === 'deleteCatalog' && (
        <CustomModal
          size="small"
          onClose={() => setShowComponent('')}
          className={'catalog_modal '}>
          <div className="permission-modal-content text-center fixed-width-inputs black-head-form-pad-left fix-width-forms">
            <p>Are you sure you want to delete this</p>

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark btn_padding"
                onClick={() => {
                  setShowComponent('');
                  if (tabType === 'invoices') {
                    removeProduct(deleteCatalog.item, deleteCatalog.index);
                  } else if (
                    subType === 'comparison' ||
                    subType === 'standard'
                  ) {
                    setDeleteCatalogItem(true);
                  }
                }}>
                Yes
              </Button>
              <div className=" text-center mt-3 as-link">
                <span
                  onClick={() => {
                    setShowComponent('');
                  }}>
                  No
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

{showComponent === 'deleteMilestone' && (
        <CustomModal
          size="small"
          onClose={() => setShowComponent('')}
          className={'catalog_modal '}>
          <div className="permission-modal-content text-center  fixed-width-inputs black-head-form-pad-left fix-width-forms">
            <p>Are you sure you want to delete this milestone</p>

            <div className="event-btn-wrapper">
              <Button
                className="btn-dark btn_padding"
                onClick={() => {
                  setShowComponent('');
                  if (tabType === 'invoices') {
                   removeMileStone(deleteCatalog.itemIndex, deleteCatalog.mileStoneIndex)
                  } 
                  else if (
                    subType === 'comparison' ||
                    subType === 'standard'
                  ) {
                    setDeleteCatalogItem(true);
                  }
                }}>
                Yes
              </Button>
              <div className=" text-center mt-3 as-link">
                <span
                  onClick={() => {
                    setShowComponent('');
                  }}>
                  No
                </span>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showInvoice === 'showInvoiceTemplate' && (
        <CreateInvoice
          type={'template'}
          getTemplates={getInvoiceTemplates}
          getDraft={getInvoiceDraft}
          templateItem={templateItem}
          onClose={() => {
            setShowInvoice('');
          }}
        />
      )}
      {showInvoice === 'showComparisonTemplate' && (
        <CreateInvoice
          type={'template'}
          subType={'comparison'}
          getTemplates={getEstimateTemplates}
          onClose={() => {
            setShowInvoice('');
          }}
          getDraft={() => {
            comparisonModalRef.current.getDraft();
          }}
          estimateTemplate={comparisonTemplateItem}
        />
      )}

      {showInvoice === 'showStandardTemplate' && (
        <CreateInvoice
          type={'template'}
          subType={'standard'}
          getTemplates={getEstimateTemplates}
          getDraft={() => {
            standardModalRef.current.getDraft();
          }}
          onClose={() => {
            setShowInvoice('');
          }}
          estimateTemplate={comparisonTemplateItem}
        />
      )}

      {showComponent === 'discardDraft' && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to discard this draft?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            discardDraft();
          }}
        />
      )}
      {showComponent === 'discardComparisonDraft' && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to discard this draft?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            comparisonModalRef.current.discardDraft();
          }}
        />
      )}

      {showComponent === 'discardStandardDraft' && (
        <ConfirmationModel
          closeModel={() => {
            setShowComponent('');
          }}
          discription={'Are you sure you want to discard this draft?'}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            standardModalRef.current.discardDraft();
          }}
        />
      )}


{showModel === 'delete_confirmation' && (
        <ConfirmationModel
          closeModel={() => {
            setShowModel('');
          }}
          discription={`Are you sure you want to delete this ${showComponent === 'editComparisonName' ? 'comparison' : 'add-on option' }?`}
          buttonOne={'Yes'}
          buttonTwo={'No'}
          ButtonOneAction={() => {
            if(showComponent ==='editComparisonName'){
              comparisonModalRef.current.deleteComparison(
                comparsionItem,
                itemIndex,
              );

              setComparisonItem({});
              setItemIndex(0);
            }else{
              standardModalRef.current.deleteStandard(
                comparsionItem,
                itemIndex,
              );
  
              setComparisonItem({});
              setAddOnValue(RadioButtonName[0]);
              setItemIndex(0);
            }
           
            setShowModel('');
          }}
        />
      )}
      {showComponent === 'createExpense' && (
        <CreateExpense
        expenseItem={expenseItem}
          expensesList={expensesList}
          setExpensesList={setExpensesList}
          setShowComponent={setShowComponent}
          showComponent={showComponent}
          onClose={() => {
            setExpenseItem({})
            setShowComponent('');
          }}
        />
      )}
    </>
  );
};

export default CreateInvoice;
