import React from 'react';
import {Button, Dropdown, Form} from 'react-bootstrap';
import {useState, useEffect, useRef} from 'react';
import SlidingPanel from '../SlidingPanel';
import Scrollbars from 'react-custom-scrollbars';
import {RoiLogo, Templates} from '../../Constants/Images';
import {errorMessage, successMessage} from '../../Utils/ToastMessages';
import {
  capitalizeFirst,
  getTimeDifference,
  updateDurations,
  generateMembershipItem,
  transformDataForDate,
  getMaxDateFromToday,
} from '../../Utils/commonUtils';
import ConfirmationModel from '../ConfirmationModel/ConfirmationModel';
import CustomDropdown from '../CustomDropdown';

import commonApi from '../../Services/CommonService';
import CustomModal from '../CustomModal';
import moment from 'moment';
import MembershipDealItem from '../Deals/MembershipDealItem';
import Calender from '../FeedBack/Calender';

const EditProject = props => {
  const {selectedProjectData} = props;

  console.log(selectedProjectData, 'selectedProjectDataselectedProjectData')
  const [tabType, setTabType] = useState('offerings');

  const [milestoneTitle, setMilestoneTitle] = useState('');
  const [deliverableTitle, setDeliverableTitle] = useState('');

  const [showDeleteModel, setShowDeleteModel] = useState('');

  const [validated, setValidated] = useState(false);
  const [dropdownId, setDropdownId] = useState('Overview');

  const [indexes, setIndexes] = useState({});
  const [membershipList, setMembershipList] = useState([]);

  const [deleteData, setDeleteData] = useState({});
  // Invite States

  const buttonRef = useRef(null);

  const [milestones, setMilestones] = useState(transformDataForDate(selectedProjectData?.milestones));

  console.log(milestones, 'kkkkkkkkkkkkkkkkkkkkkkkkkkk');
  console.log(selectedProjectData, 'mmmmmmmmmmmmmmm');

  const [activeMilestoneIndex, setActiveMilestoneIndex] = useState(null);
  const [activeDeliverableIndex, setActiveDeliverableIndex] = useState(null);
  const [productDetail, setProductDetail] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [searchOfferingTask, setSearchOfferingTask] = useState('');
  const today = new Date();
  const [showDateModal, setShowDateModal] = useState(false);
  const [projectDetail, setProjectDetail] = useState(null);
  const [showComponent, setShowComponent] = useState('');

  const [editProject, setEditProject] = useState(false);


  const [mile_index, setMile_index] = useState(0);
  const [deli_index, setDeli_index] = useState(0);
  const [milestoneDropdown, setMilestoneDropdown] = useState('');
  const [deliverableDropdown, setDeliverableDropdown] = useState('');

  const handleMilestoneSelect = selectedTitle => {
    setMilestoneDropdown(selectedTitle);

    const index = milestones.findIndex(item => item.id === selectedTitle);
    setMile_index(index);

    setDeliverableDropdown('');
  };

  useEffect(() => {
    if (milestones && milestones.length > 0) {
      setMilestoneDropdown(milestones[0].id);
      setMile_index(0);
    }
  }, [dropdownId]);

  const handleDeliverableSelect = selectedTitle => {
    const index = milestones[mile_index]?.deliverables.findIndex(
      item => item.title === selectedTitle,
    );
    setDeli_index(index);

    setDeliverableDropdown(selectedTitle);
  };

  const addDeliverable = (milestoneIndex, title) => {
    const trimmedTitle = title.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
      errorMessage('Deliverable title cannot be empty');
      return;
    }
    const newMilestones = [...milestones];
    const milestone = newMilestones[milestoneIndex];

    // Check if the deliverable with the same title already exists in the current milestone
    const deliverableExists = milestone.deliverables.some(
      d => d.title === title,
    );

    if (deliverableExists) {
      // Title already exists, show error
      errorMessage('Deliverable title already exists.');
      return; // Exit the function
    } else {
      setDeliverableTitle('');
      // Deliverable does not exist, so add it
      milestone.deliverables.push({
        title: title,
        task_ids: [],
        offering_ids: [],
      });
    }

    setMilestones(newMilestones);
  };
  const deleteDeliverable = (milestoneIndex, title) => {
    const newMilestones = [...milestones];
    const milestone = newMilestones[milestoneIndex];

    // Find the index of the deliverable with the given title
    const deliverableIndex = milestone.deliverables.findIndex(
      d => d.title === title,
    );

    if (deliverableIndex !== -1) {
      // Deliverable exists, so remove it
      milestone.deliverables.splice(deliverableIndex, 1);
      setShowDeleteModel('');
    } else {
      // Deliverable does not exist, show error
      errorMessage('Deliverable title not found.');
      return; // Exit the function
    }

    setMilestones(newMilestones);
  };

  const addTaskOrOffering = (milestoneIndex, deliverableIndex, type, item) => {
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[milestoneIndex].deliverables[deliverableIndex];

    let membershipList = [];
    let memberShip = false;
    if (type === 'offering') {
      if (item.catalog_details && item.catalog_details.length > 0) {
        // Push all catalog_details items into offering_ids
        item.catalog_details.forEach(detail => {
          if (detail.catalog_type === 'membership') {
            membershipList.push(generateMembershipItem(detail));
            memberShip = true;
          } else {
            deliverable.offering_ids.push(detail);
          }
        });

        if (memberShip) {
          setMembershipList(membershipList);
          setIndexes({
            milestoneIndex: milestoneIndex,
            deliverableIndex: deliverableIndex,
          });
          setShowComponent('membershipLevel');
        }
      } else {
        // Push the single item into offering_ids

        if (item.catalog_type === 'membership') {
          membershipList.push(generateMembershipItem(item));

          setMembershipList(membershipList);
          setIndexes({
            milestoneIndex: milestoneIndex,
            deliverableIndex: deliverableIndex,
          });
          setShowComponent('membershipLevel');
        } else if (
          !deliverable.offering_ids.some(offering => offering.id === item.id)
        ) {
          deliverable.offering_ids.push(item);
        } else {
          errorMessage('You have already added this offering');
        }
      }
      setProductDetail([]);
      setSearchOfferingTask('');
    } else if (type === 'task') {
      if (!deliverable.task_ids.some(task_ids => task_ids.id === item.id)) {
        deliverable.task_ids.push(item);
        setTaskList([]);
        setSearchOfferingTask('');
      } else {
        errorMessage('You have already added this task');
      }
    }
    setMilestones(newMilestones);
  };

  const deleteTaskOrOffering = (
    milestoneIndex,
    deliverableIndex,
    type,
    itemIndex,
  ) => {
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[milestoneIndex].deliverables[deliverableIndex];

    if (type === 'offering') {
      deliverable.offering_ids.splice(itemIndex, 1);
    } else if (type === 'task') {
      deliverable.task_ids.splice(itemIndex, 1);
    }

    setMilestones(newMilestones);
  };
  const dropdownValues = [
    {title: 'Overview', id: 'Overview'},
    {id: 'MileStones', title: 'MileStones'},
    {id: 'Deliverables', title: 'Deliverables'},
    {id: 'Tasks', title: 'Tasks'},
    {id: 'Offerings', title: 'Offerings'},
    {id: 'Uploads', title: 'Uploads'},
  ];

  const renderProjeectButtons = profile => (
    <>
      {localStorage.getItem('id') === localStorage.getItem('user') ? (
        !editProject ?
        <p
          className="p-bold m-0 f_600_h"
          onClick={() => setEditProject(true)}
          style={{cursor: 'pointer'}}>
          Edit
        </p>
        :
        <img src={RoiLogo} 
        onClick={() => setEditProject(false)}
        alt="" 
        style={{cursor: 'pointer'}}/>
      ) : (
        <img src={RoiLogo} alt="" />
      )}
    </>
  );

  const handleClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const handleProjectDetail = () => {
    const data = {
      milestones:
        milestones && milestones.length > 0
          ? milestones.map(milestone => ({
              title: milestone.title,

              deliverables:
                milestone.deliverables && milestone.deliverables.length > 0
                  ? milestone.deliverables.map(deliverable => {
                      const offeringData = deliverable.offering_ids.map(
                        item => ({
                          id: item?.level
                            ? item?.level?.frequency_id
                            : item.id || null,
                          date: item.duration || '',
                          type: item.catalog_type || item.type,
                        }),
                      );

                      const taskData = deliverable.task_ids.map(item => ({
                        id: item.id || null,
                        date: item.due_date || '',
                      }));

                      return {
                        title: deliverable.title,
                        offering_ids: offeringData || [],
                        task_ids: taskData || [],
                      };
                    })
                  : [],
            }))
          : [],
    };

    const {
      name = '',
      id: project_id = '',
      tag_titles,
      team_alias = '',
      customer_ids = '',
      poc_ids = '',
    } = selectedProjectData || {};

    const payload = {
      name,
      project_id,
      tag_titles,
      team_alias,
      customer_ids,
      poc_ids,
      milestones: data.milestones,
      deliverable_id: '',
      milestone_id: '',
    };


    commonApi
      .create_update_projects(payload)
      .then(res => {
        if (res.status === 200) {
          if (res.success) {
            successMessage(res.message);
            props.handleProjectAddEdit(res.data, 'edit');
            props.onClose();
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const handle = e => {
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      e.preventDefault();
      handleProjectDetail();
    }
  };

  const addMilestone = title => {
    // Trim the title to remove any leading/trailing whitespace
    const trimmedTitle = title.trim();

    // Check if the title is empty or consists only of whitespace
    if (trimmedTitle === '') {
      errorMessage('Milestone title cannot be empty');
      return;
    }

    // Check if a milestone with the same title already exists
    const milestoneExists = milestones.some(m => m.title === trimmedTitle);

    if (!milestoneExists) {
      setMilestones([...milestones, {title: trimmedTitle, deliverables: []}]);
      setMilestoneTitle('');
    } else {
      errorMessage('Milestone title already exists');
    }
  };

  const handleSaveMemberShip = () => {
    const Membership = updateDurations(membershipList, today);
    const newMilestones = [...milestones];
    const deliverable =
      newMilestones[indexes?.milestoneIndex].deliverables[
        indexes?.deliverableIndex
      ];

    deliverable.offering_ids = [...deliverable.offering_ids, ...Membership];
    setMilestones(newMilestones);

    setShowComponent('');
    setMembershipList([]);
    setIndexes({});
  };

  const deleteMilestone = index => {
    const newMilestones = [...milestones];

    // Remove the milestone at the specified index
    newMilestones.splice(index, 1);
    setShowDeleteModel('');
    setActiveMilestoneIndex(null);

    setMilestones(newMilestones);
  };

  const get_catalogs_for_invoices = value => {
    const payload = {search_query: value, type: 'web'};
    commonApi
      .get_catalogs_for_invoices(payload)
      .then(res => {
        if (res.status === 200) {
          if (!value || value === '') {
            setProductDetail([]);
          } else {
            setProductDetail(res.catalog_data);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };

  const get_actions_for_project_managemnet = value => {
    const payload = {search_query: value};
    commonApi
      .get_actions_for_project_managemnet(payload)
      .then(res => {
        if (res.status === 200) {
          if (!value || value === '') {
            setTaskList([]);
          } else {
            setTaskList(res.all_actions);
          }
        }
      })
      .catch(err => {
        console.log('Err', err);
        return {type: 'error', message: err.message};
      });
  };
  useEffect(() => {
    setSearchOfferingTask('');
  }, [tabType]);
  useEffect(() => {
    setMilestones(transformDataForDate(selectedProjectData?.milestones));
  }, [selectedProjectData]);




  const removeName = () => {
    if (deleteData.type === 'milestone') {
      deleteMilestone(deleteData.milestone_index);
    } else if (deleteData.type === 'deliverable') {
      deleteDeliverable(deleteData.deliverable_index, deleteData.title);
    } else if (deleteData.type === 'offering') {
      deleteTaskOrOffering(
        deleteData.milestone_index,
        deleteData.deliverable_index,
        deleteData.type,
        deleteData.Offering_index,
      );
    } else if (deleteData.type === 'task') {
      deleteTaskOrOffering(
        deleteData.milestone_index,
        deleteData.deliverable_index,
        deleteData.type,
        deleteData.Task_index,
      );
    }
    setDeleteData({
      model: false,
    });
  };

  const checkDateFormat = input => {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    const isDateFormat = datePattern.test(input);

    const isValidDate = isDateFormat ? !isNaN(new Date(input)) : false;

    return isValidDate ? input : '0';
  };

  const handleDates = () => {
    const newMilestones = [...milestones];

    if (tabType === 'tasks' || dropdownId === 'Tasks') {
      milestones[projectDetail.index_Milestone].deliverables[
        projectDetail.index_Deliverable
      ].task_ids[projectDetail.index_Task].due_date = projectDetail.date;
    } else if (dropdownId === 'Offerings' || tabType === 'offerings') {
      milestones[projectDetail.index_Milestone].deliverables[
        projectDetail.index_Deliverable
      ].offering_ids[projectDetail.index_Offering].duration =
        projectDetail.date;
    }
    setMilestones(newMilestones);
  };

  return (
    <>
      <SlidingPanel
        hidePanel={() => {
          props.onClose();
          setMilestones([])
        }}
        renderData={renderProjeectButtons}
        showBlackHeader={true}
        lastDivStyle={{
          borderRadius: '35px',
          background: '#F7F6F2',
          // marginBottom: '20px',
        }}
        firstDivStyle={{
          padding: 0,
          background:
            'linear-gradient(to bottom, #424242, #424242, #f7f6f2 50%)' /*linear-gradient(to bottom, black, white)*/,
        }}>
        <div
          className="serach-filter-wrapper create-sessionEvents"
          style={{
            paddingLeft: '23px',
            paddingTop: '20px',
          }}>
          <div className="create-ticket-form  primary_panel_content">
            <div className="messages-header mb-2 fix-width-forms ">
              <div className="event-tabs tabs-style-2 mb-2">
                {' '}
                <ul>
                  <li className="f_700">
                    {/* {props.editProject ? 'Update Project' : 'Create Project'} */}
                  </li>
                </ul>
              </div>

              <div className="search-tabs-icons">
                <img
                  className="template-img"
                  src={Templates}
                  alt=""
                  onClick={() => {}}
                />
              </div>
            </div>

            <div className=" create-template refferal-list-wrapper">
              <Scrollbars autoHide>
                <div className=" create-project-content fix-width-forms  ">
                  <Form noValidate validated={validated} onSubmit={handle}>
                    <div style={{marginBottom: '72px'}} className="customer-name-fields-holder">
                      <div className="fieldset">
                        {/* <Form.Label controlid="validationCustom01">
                          Project Name
                        </Form.Label> */}
                        <CustomDropdown
                          setDropdownId={setDropdownId}
                          dropdownId={dropdownId}
                          DropDownValues={dropdownValues}
                          itemName={['title']}
                          className={'mb-3'}
                        />
                      </div>

                      {dropdownId === 'MileStones' && (
                        <>
                          <div className="d-flex">
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  {!editProject && milestones?.length > 0
                                    ? 'Milestones'
                                    : editProject
                                    && 'Milestone Title'}
                                </Form.Label>
                                {editProject && (
                                  <span
                                    className={'icon search-tabs-icons as-link'}
                                    style={{marginRight: '5px'}}>
                                    <i
                                      className="icon-plus"
                                      onClick={() => {
                                        //   addMilesStone_list(milestoneTitle);
                                        addMilestone(milestoneTitle);
                                      }}
                                    />
                                  </span>
                                )}
                              </div>
                              {editProject && (
                                <div className="input-plus-icon-holder">
                                  <Form.Control
                                    name="phase"
                                    value={milestoneTitle}
                                    pattern="^\S.*$"
                                    placeholder="Milestone Title"
                                    // onChange={handleInputChange}
                                    onChange={e => {
                                      setMilestoneTitle(e.target.value);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="mb-3 milesstones-field-block">
                            {milestones &&
                            milestones.length > 0 &&
                            Object.entries(milestones).length > 0
                              ? milestones.map((milestone, mile_Index) => {
                                  const isActive =
                                    mile_Index === activeMilestoneIndex;

                                  return (
                                    <div
                                      key={mile_Index}
                                      className="d-flex flex-column mt-3">
                                      <div className="d-flex align-items-center phases_item">
                                        <div
                                          className="customer_name flex-1"
                                          style={{cursor: 'pointer'}}
                                          onClick={() =>
                                            setActiveMilestoneIndex(
                                              isActive ? null : mile_Index,
                                            )
                                          }>
                                          <div className="deliverables_list mt-0">
                                            <div className="deliverable_item">
                                              {capitalizeFirst(milestone.title)}
                                            </div>
                                          </div>
                                        </div>
                                        {editProject && (
                                          <span
                                            className="as-link cross-icon-field"
                                            onClick={() => {
                                              setDeleteData({
                                                milestone_index: mile_Index,
                                                type: 'milestone',
                                                model: true,
                                              });
                                            }}>
                                            <i
                                              className="fas fa-times text-secondary"
                                              style={{fontSize: '1.5rem'}}></i>
                                          </span>
                                        )}
                                      </div>

                                      {isActive && (
                                        <div className="d-flex mt-3">
                                          <div
                                            className="fieldset flex-1"
                                            style={{margin: '0px'}}>
                                            <div className="d-flex justify-content-between input-plus-icon-holder">
                                              <Form.Label controlId="validationCustom01">
                                              {!editProject && milestone?.deliverables?.length > 0
                                                ? 'Deliverables'
                                                : editProject
                                                && 'Deliverable Title'
                                                }
                                              </Form.Label>
                                              {editProject && (
                                                <span
                                                  className="icon search-tabs-icons as-link"
                                                  style={{marginRight: '5px'}}
                                                  onClick={() =>
                                                    addDeliverable(
                                                      activeMilestoneIndex,
                                                      deliverableTitle,
                                                    )
                                                  }>
                                                  <i className="icon-plus" />
                                                </span>
                                              )}
                                            </div>
                                            {editProject && (
                                              <div className="input-plus-icon-holder">
                                                <Form.Control
                                                  name="phase"
                                                  value={deliverableTitle}
                                                  pattern="^\S.*$"
                                                  placeholder="Deliverable Title"
                                                  onChange={e =>
                                                    setDeliverableTitle(
                                                      e.target.value,
                                                    )
                                                  }
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {isActive &&
                                        milestone?.deliverables?.length > 0 && (
                                          <div className="mb-3">
                                            {milestone?.deliverables.length > 0
                                              ? milestone?.deliverables.map(
                                                  (
                                                    deliverable,
                                                    deliverable_Index,
                                                  ) => (
                                                    <div
                                                      key={deliverable_Index}>
                                                      <div className="d-flex align-items-center phases_item mt-3">
                                                        <div
                                                          className="customer_name flex-1"
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={() =>
                                                            setActiveDeliverableIndex(
                                                              activeDeliverableIndex ===
                                                                deliverable_Index
                                                                ? null
                                                                : deliverable_Index,
                                                            )
                                                          }>
                                                          <div className="deliverables_list mt-0">
                                                            <div className="deliverable_item">
                                                              {capitalizeFirst(
                                                                deliverable.title,
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {editProject && (
                                                          <span
                                                            className="as-link cross-icon-field"
                                                            onClick={e => {
                                                              setDeleteData({
                                                                deliverable_index:
                                                                  deliverable_Index,
                                                                title:
                                                                  deliverable.title,
                                                                type: 'deliverable',
                                                                model: true,
                                                              });
                                                            }}>
                                                            <i
                                                              className="fas fa-times text-secondary"
                                                              style={{
                                                                fontSize:
                                                                  '1.5rem',
                                                              }}></i>
                                                          </span>
                                                        )}
                                                      </div>
                                                      {isActive &&
                                                        activeDeliverableIndex ===
                                                          deliverable_Index && (
                                                          <>
                                                            <div className="event-tabs tabs-style-2 mb-0 mt-2">
                                                              <ul className="LabelTabs">
                                                                <li className="ml-20 d-flex align-items-center">
                                                                  <Button
                                                                    className={
                                                                      tabType ===
                                                                      'offerings'
                                                                        ? 'active'
                                                                        : ''
                                                                    }
                                                                    onClick={() =>
                                                                      setTabType(
                                                                        'offerings',
                                                                      )
                                                                    }>
                                                                    Offerings
                                                                  </Button>
                                                                </li>
                                                                <li className="ml-20 d-flex align-items-center">
                                                                  <Button
                                                                    className={
                                                                      tabType ===
                                                                      'tasks'
                                                                        ? 'active'
                                                                        : ''
                                                                    }
                                                                    onClick={() =>
                                                                      setTabType(
                                                                        'tasks',
                                                                      )
                                                                    }>
                                                                    Tasks
                                                                  </Button>
                                                                </li>
                                                              </ul>
                                                            </div>
                                                            <div className="mt-3">
                                                              {editProject && (
                                                                <div className="input-plus-icon-holder">
                                                                  <Form.Control
                                                                    name="phase"
                                                                    value={
                                                                      searchOfferingTask
                                                                    }
                                                                    pattern="^\S.*$"
                                                                    placeholder={`Search ${tabType}`}
                                                                    onChange={e => {
                                                                      setSearchOfferingTask(
                                                                        e.target
                                                                          .value,
                                                                      );
                                                                      if (
                                                                        tabType ===
                                                                        'offerings'
                                                                      ) {
                                                                        get_catalogs_for_invoices(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                        );
                                                                      } else {
                                                                        get_actions_for_project_managemnet(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                        );
                                                                      }
                                                                    }}
                                                                  />
                                                                </div>
                                                              )}
                                                              {tabType ===
                                                                'offerings' &&
                                                                deliverable.offering_ids && (
                                                                  <div>
                                                                    <div className="mb-3">
                                                                      {searchOfferingTask &&
                                                                        productDetail.length >
                                                                          0 && (
                                                                          <ul className="custom-suggestions invoices_suggestion">
                                                                            {productDetail.map(
                                                                              suggestion => (
                                                                                <li
                                                                                  className="as-link"
                                                                                  key={
                                                                                    'suggestion' +
                                                                                    suggestion.id
                                                                                  }
                                                                                  onClick={() => {
                                                                                    addTaskOrOffering(
                                                                                      mile_Index,
                                                                                      deliverable_Index,
                                                                                      'offering',
                                                                                      suggestion,
                                                                                    );
                                                                                  }}>
                                                                                  {capitalizeFirst(
                                                                                    suggestion.title,
                                                                                  )}
                                                                                </li>
                                                                              ),
                                                                            )}
                                                                          </ul>
                                                                        )}

                                                                      {deliverable
                                                                        .offering_ids
                                                                        .length >
                                                                        0 &&
                                                                      Object.entries(
                                                                        deliverable.offering_ids,
                                                                      ).length >
                                                                        0
                                                                        ? deliverable.offering_ids.map(
                                                                            (
                                                                              item,
                                                                              indexOffering,
                                                                            ) => (
                                                                              <div
                                                                                className="d-flex align-items-center phases_item mt-3"
                                                                                key={
                                                                                  indexOffering
                                                                                }>
                                                                                <div
                                                                                  className="customer_name flex-1"
                                                                                  style={{
                                                                                    backgroundColor:
                                                                                      'white',
                                                                                  }}>
                                                                                  <div className="alias_name">
                                                                                    {capitalizeFirst(
                                                                                      item.title,
                                                                                    )}
                                                                                  </div>
                                                                                  <div className="d-flex align-items-center">
                                                                                    <div
                                                                                      style={{
                                                                                        cursor: editProject ? 'pointer' : 'default',
                                                                                        color:
                                                                                          '#5ABD42',
                                                                                        marginRight:
                                                                                          '10px',
                                                                                      }}
                                                                                      onClick={() => {
                                                                                        editProject &&
                                                                                          setShowDateModal(
                                                                                            true,
                                                                                          );

                                                                                          const formattedDuration = checkDateFormat(item.duration)

                                                                                        setProjectDetail(
                                                                                          prev => ({
                                                                                            ...prev,
                                                                                            date: formattedDuration,
                                                                                            index_Offering:
                                                                                              indexOffering,
                                                                                            index_Deliverable:
                                                                                              deliverable_Index,
                                                                                            index_Milestone:
                                                                                              mile_Index,
                                                                                          }),
                                                                                        );
                                                                                      }}>
                                                                                      {
                                                                                      (item.duration &&
                                                                                        item.duration !==
                                                                                          '')
                                                                                        ? checkDateFormat(
                                                                                              item.duration,
                                                                                          ) !==
                                                                                          '0'
                                                                                          ? getTimeDifference(
                                                                                                item.duration,
                                                                                            )
                                                                                          : 'Select date'
                                                                                        : 'Select date'}
                                                                                    </div>
                                                                                    {editProject && (
                                                                                      <span className="as-link cross-icon-field">
                                                                                        <i
                                                                                          className="fas fa-times text-secondary"
                                                                                          onClick={() => {
                                                                                            setDeleteData(
                                                                                              {
                                                                                                milestone_index:
                                                                                                  mile_Index,
                                                                                                deliverable_index:
                                                                                                  deliverable_Index,
                                                                                                type: 'offering',
                                                                                                Offering_index:
                                                                                                  indexOffering,
                                                                                                model: true,
                                                                                              },
                                                                                            );
                                                                                          }}
                                                                                          style={{
                                                                                            fontSize:
                                                                                              '1.5rem',
                                                                                          }}></i>
                                                                                      </span>
                                                                                    )}
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            ),
                                                                          )
                                                                        : !editProject && (
                                                                          <div className="praise-list-item no-border pt-0">
                                                                            <p className="text-center">
                                                                              No
                                                                              offerings
                                                                              available
                                                                            </p>
                                                                            </div>
                                                                          )}
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              {tabType ===
                                                                'tasks' &&
                                                                deliverable?.task_ids && (
                                                                  <div>
                                                                    <div className="">
                                                                      {searchOfferingTask &&
                                                                        taskList?.length >
                                                                          0 && (
                                                                          <ul className="custom-suggestions invoices_suggestion">
                                                                            {taskList.map(
                                                                              suggestion => (
                                                                                <li
                                                                                  className="as-link"
                                                                                  key={
                                                                                    'suggestion' +
                                                                                    suggestion.id
                                                                                  }
                                                                                  onClick={() => {
                                                                                    addTaskOrOffering(
                                                                                      mile_Index,
                                                                                      deliverable_Index,
                                                                                      'task',
                                                                                      suggestion,
                                                                                    );
                                                                                  }}>
                                                                                  {capitalizeFirst(
                                                                                    suggestion.title,
                                                                                  )}
                                                                                </li>
                                                                              ),
                                                                            )}
                                                                          </ul>
                                                                        )}

                                                                      {Object.entries(
                                                                        deliverable?.task_ids,
                                                                      )
                                                                        ?.length >
                                                                      0
                                                                        ? deliverable.task_ids.map(
                                                                            (
                                                                              item,
                                                                              indexTask,
                                                                            ) => {
                                                                              return (
                                                                                <div
                                                                                  className="d-flex align-items-center mt-3"
                                                                                  key={
                                                                                    indexTask
                                                                                  }>
                                                                                  <div
                                                                                    className="customer_name flex-1"
                                                                                    style={{
                                                                                      backgroundColor:
                                                                                        'white',
                                                                                    }}>
                                                                                    <div className="alias_name">
                                                                                      {capitalizeFirst(
                                                                                        item.title,
                                                                                      )}
                                                                                    </div>
                                                                                    <div className="d-flex align-items-center">
                                                                                      <div
                                                                                        style={{
                                                                                          cursor: editProject ? 'pointer' : 'default',
                                                                                          color:
                                                                                            '#5ABD42',
                                                                                          marginRight:
                                                                                            '10px',
                                                                                        }}
                                                                                        onClick={() => {
                                                                                          editProject &&
                                                                                            setShowDateModal(
                                                                                              true,
                                                                                            );

                                                                                          setProjectDetail(
                                                                                            prev => ({
                                                                                              ...prev,
                                                                                              date: item.due_date &&
                                                                                              item.due_date !==
                                                                                                '0000-00-00 00:00:00'
                                                                                                ? item.due_date
                                                                                                : '',
                                                                                              index_Task:
                                                                                                indexTask,
                                                                                              index_Deliverable:
                                                                                                deliverable_Index,
                                                                                              index_Milestone:
                                                                                                mile_Index,
                                                                                            }),
                                                                                          );
                                                                                        }}>
                                                                                        {
                                                                                          item.due_date &&
                                                                                          item.due_date !==
                                                                                            '0000-00-00 00:00:00'
                                                                                            ? getTimeDifference(item.due_date)
                                                                                            : 'Select date'
                                                                                      }
                                                                                      </div>
                                                                                      {editProject && (
                                                                                        <span className="as-link cross-icon-field">
                                                                                          <i
                                                                                            className="fas fa-times text-secondary"
                                                                                            onClick={() => {
                                                                                              setDeleteData(
                                                                                                {
                                                                                                  milestone_index:
                                                                                                    mile_Index,
                                                                                                  deliverable_index:
                                                                                                    deliverable_Index,
                                                                                                  type: 'task',
                                                                                                  Task_index:
                                                                                                    indexTask,
                                                                                                  model: true,
                                                                                                },
                                                                                              );
                                                                                            }}
                                                                                            style={{
                                                                                              fontSize:
                                                                                                '1.5rem',
                                                                                            }}></i>
                                                                                        </span>
                                                                                      )}
                                                                                      {/* </div> */}
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              );
                                                                            },
                                                                          )
                                                                        : !editProject && (
                                                                          <div className="praise-list-item no-border pt-0">
                                                                            <p className="text-center">
                                                                              No
                                                                              tasks
                                                                              available
                                                                            </p>
                                                                            </div>
                                                                          )}
                                                                    </div>
                                                                  </div>
                                                                )}
                                                            </div>
                                                          </>
                                                        )}
                                                    </div>
                                                  ),
                                                )
                                              : !editProject && (
                                                <div className="praise-list-item no-border pt-0">
                                                  <p>
                                                    No deliverables available
                                                  </p>
                                                  </div>
                                                )}
                                          </div>
                                        )}
                                    </div>
                                  );
                                })
                              : !editProject && (
                                <div className="praise-list-item no-border pt-0">
                                  <p className="text-center">
                                    No milestones available
                                  </p>
                                  </div>
                                )}
                          </div>
                        </>
                      )}
                      {(dropdownId === 'Tasks' ||
                        dropdownId === 'Offerings' ||
                        dropdownId === 'Deliverables') &&
                        milestones.length > 0 && (
                          <>
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  Milestones
                                </Form.Label>
                              </div>
                            </div>

                            <CustomDropdown
                              setDropdownId={setMilestoneDropdown}
                              dropdownId={milestoneDropdown}
                              DropDownValues={milestones}
                              itemName={['title']}
                              className={'mb-3'}
                              type={'milestone'}
                              handleDropdownValue={handleMilestoneSelect}
                            />
                          </>
                        )}

                      {(dropdownId === 'Tasks' || dropdownId === 'Offerings') &&
                        milestones.length > 0 &&
                        mile_index !== null &&
                        mile_index !== -1 &&
                        milestones[mile_index].deliverables.length > 0 && (
                          <>
                            <div
                              className="fieldset flex-1"
                              style={{margin: '0px'}}>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlid="validationCustom01">
                                  Deliverables
                                </Form.Label>
                              </div>
                            </div>

                            <Dropdown
                              className="deliverable-dropdowns mb-3"
                              onSelect={(eventKey, event) => {
                                setDeliverableDropdown(eventKey);
                                handleDeliverableSelect(eventKey);
                               
                                // setDeleteItem(false);
                              }}
                              drop="down">
                              <Dropdown.Toggle variant="primary">
                                {deliverableDropdown
                                  ? milestones[mile_index].deliverables.find(
                                      item =>
                                        item.title === deliverableDropdown,
                                    ).title
                                  : 'Select Role'}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {milestones[mile_index].deliverables.map(
                                  (item, index) => (
                                    <Dropdown.Item
                                      key={`st-${index}`}
                                      eventKey={item.title}
                                      active={
                                        item.title === deliverableDropdown
                                      }>
                                      {item.title}
                                    </Dropdown.Item>
                                  ),
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </>
                        )}

                      {dropdownId === 'Offerings' ? (
                        milestoneDropdown &&
                        deliverableDropdown &&
                        milestones.length > 0 &&
                        mile_index !== null &&
                        deli_index !== null ? (
                          milestones[mile_index]?.deliverables[deli_index]
                            ?.offering_ids?.length > 0 ? (
                            milestones[mile_index]?.deliverables[
                              deli_index
                            ]?.offering_ids.map((item, index) => {

                              return (
                                <div key={index}>
                                  <div className="d-flex align-items-center mt-3">
                                    <div
                                      className="customer_name flex-1"
                                      style={{backgroundColor: 'white'}}>
                                      <div className="alias_name">
                                        {capitalizeFirst(item.title)}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            cursor: editProject ? 'pointer' : 'default',
                                            color: '#5ABD42',
                                            marginRight: '10px',
                                          }}
                                          onClick={() => {
                                            if (editProject) {
                                              setShowDateModal(true);

                                                  const formattedDuration = checkDateFormat(item.duration)


                                              setProjectDetail(prev => ({
                                                ...prev,
                                                date: formattedDuration,
                                                index_Offering: index,
                                                index_Deliverable: deli_index,
                                                index_Milestone: mile_index,
                                              }));
                                            }
                                          }}> 
                                          {
                                            (item.duration &&
                                              item.duration !==
                                                '')
                                              ? checkDateFormat(
                                                    item.duration,
                                                ) !==
                                                '0'
                                                ? getTimeDifference(
                                                      item.duration,
                                                  )
                                                : 'Select date'
                                              : 'Select date'}

                                        </div>
                                        {editProject && (
                                          <span className="as-link cross-icon-field">
                                            <i
                                              className="fas fa-times text-secondary"
                                              onClick={() => {
                                                setDeleteData({
                                                  milestone_index: mile_index,
                                                  deliverable_index: deli_index,
                                                  type: 'offering',
                                                  Offering_index: index,
                                                  model: true,
                                                });
                                              }}
                                              style={{fontSize: '1.5rem'}}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="praise-list-item no-border pt-0">
                            <p className="text-center">
                              No {dropdownId} available
                            </p>
                            </div>
                          )
                        ) : (
                          <div className="praise-list-item no-border pt-0">
                          <p className="text-center">
                            No {dropdownId} available
                          </p>
                          </div>
                        )
                      ) : dropdownId === 'Tasks' ? (
                        milestoneDropdown &&
                        deliverableDropdown &&
                        milestones.length > 0 &&
                        mile_index !== null &&
                        deli_index !== null ? (
                          milestones[mile_index]?.deliverables[deli_index]
                            ?.task_ids?.length > 0 ? (
                            milestones[mile_index]?.deliverables[
                              deli_index
                            ]?.task_ids.map((item, index) => {
                              return (
                                <div key={index}>
                                  <div className="d-flex align-items-center mt-3">
                                    <div
                                      className="customer_name flex-1"
                                      style={{backgroundColor: 'white'}}>
                                      <div className="alias_name">
                                        {capitalizeFirst(item.title)}
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div
                                          style={{
                                            cursor: editProject
                                              ? 'pointer'
                                              : 'default',
                                            color: '#5ABD42',
                                            marginRight: '10px',
                                          }}
                                          onClick={() => {
                                            editProject &&
                                              setShowDateModal(true);

                                            setProjectDetail(prev => ({
                                              ...prev,
                                              date:
                                                item.due_date &&
                                                item.due_date !==
                                                  '0000-00-00 00:00:00'
                                                  ? item.due_date
                                                  : '',

                                              index_Task: index,
                                              index_Deliverable: deli_index,
                                              index_Milestone: mile_index,
                                            }));
                                          }}>
                                          {item.due_date && item.due_date !== ''
                                            ? getTimeDifference(item.due_date)
                                            : 'Select date'}
                                        </div>
                                        {editProject && (
                                          <span className="as-link cross-icon-field">
                                            <i
                                              className="fas fa-times text-secondary"
                                              onClick={() => {
                                                setDeleteData({
                                                  milestone_index: mile_index,
                                                  deliverable_index: deli_index,
                                                  type: 'task',
                                                  Task_index: index,
                                                  model: true,
                                                });
                                              }}
                                              style={{fontSize: '1.5rem'}}
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="praise-list-item no-border pt-0">
                            <p className="text-center">
                              No {dropdownId} available
                            </p>
                            </div>
                          )
                        ) : (
                          <div className="praise-list-item no-border pt-0">
                          <p className="text-center">
                            No {dropdownId} available
                          </p>
                          </div>
                        )
                      ) : dropdownId === 'Deliverables' ? (
                        <>
                          {editProject && (
                            <>
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlId="validationCustom01">
                                  {!editProject
                                    ? 'Deliverables'
                                    : 'Deliverable Title'}
                                </Form.Label>
                                <span
                                  className="icon search-tabs-icons as-link"
                                  style={{marginRight: '5px'}}
                                  onClick={() =>
                                    addDeliverable(mile_index, deliverableTitle)
                                  }>
                                  <i className="icon-plus" />
                                </span>
                              </div>

                              <div className="input-plus-icon-holder">
                                <Form.Control
                                  name="phase"
                                  value={deliverableTitle}
                                  pattern="^\S.*$"
                                  placeholder="Deliverable Title"
                                  onChange={e =>
                                    setDeliverableTitle(e.target.value)
                                  }
                                />
                              </div>
                            </>
                          )}

                          {milestones.length > 0 &&
                          mile_index !== null &&
                          deli_index !== null ? (
                            milestones[mile_index]?.deliverables?.length > 0 ? (
                              milestones[mile_index].deliverables.map(
                                (item, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="d-flex align-items-center phases_item mt-3">
                                        <div
                                          className="customer_name flex-1"
                                          style={{cursor: 'pointer'}}
                                          onClick={() =>
                                            setActiveDeliverableIndex(
                                              activeDeliverableIndex === index
                                                ? null
                                                : index,
                                            )
                                          }>
                                          <div className="deliverables_list mt-0">
                                            <div className="deliverable_item">
                                              {capitalizeFirst(item.title)}
                                            </div>
                                          </div>
                                        </div>
                                        {editProject && (
                                          <span
                                            className="as-link cross-icon-field"
                                            onClick={e => {
                                              setDeleteData({
                                                deliverable_index: index,
                                                title: item.title,
                                                type: 'deliverable',
                                                model: true,
                                              });
                                            }}>
                                            <i
                                              className="fas fa-times text-secondary"
                                              style={{
                                                fontSize: '1.5rem',
                                              }}></i>
                                          </span>
                                        )}
                                      </div>
                                      {activeDeliverableIndex === index && (
                                        <>
                                          <div className="event-tabs tabs-style-2 mb-0 mt-2">
                                            <ul className="LabelTabs">
                                              <li className="ml-20 d-flex align-items-center">
                                                <Button
                                                  className={
                                                    tabType === 'offerings'
                                                      ? 'active'
                                                      : ''
                                                  }
                                                  onClick={() =>
                                                    setTabType('offerings')
                                                  }>
                                                  Offerings
                                                </Button>
                                              </li>
                                              <li className="ml-20 d-flex align-items-center">
                                                <Button
                                                  className={
                                                    tabType === 'tasks'
                                                      ? 'active'
                                                      : ''
                                                  }
                                                  onClick={() =>
                                                    setTabType('tasks')
                                                  }>
                                                  Tasks
                                                </Button>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="mt-3">
                                            {editProject && (
                                              <div className="input-plus-icon-holder">
                                                <Form.Control
                                                  name="phase"
                                                  value={searchOfferingTask}
                                                  pattern="^\S.*$"
                                                  placeholder={`Search ${tabType}`}
                                                  onChange={e => {
                                                    setSearchOfferingTask(
                                                      e.target.value,
                                                    );
                                                    if (
                                                      tabType === 'offerings'
                                                    ) {
                                                      get_catalogs_for_invoices(
                                                        e.target.value,
                                                      );
                                                    } else {
                                                      get_actions_for_project_managemnet(
                                                        e.target.value,
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {tabType === 'offerings' &&
                                              item.offering_ids && (
                                                <div>
                                                  <div className="mb-3">
                                                    {searchOfferingTask &&
                                                      productDetail.length >
                                                        0 && (
                                                        <ul className="custom-suggestions invoices_suggestion">
                                                          {productDetail.map(
                                                            suggestion => (
                                                              <li
                                                                className="as-link"
                                                                key={
                                                                  'suggestion' +
                                                                  suggestion.id
                                                                }
                                                                onClick={() => {
                                                                  addTaskOrOffering(
                                                                    mile_index,
                                                                    index,
                                                                    'offering',
                                                                    suggestion,
                                                                  );
                                                                }}>
                                                                {capitalizeFirst(
                                                                  suggestion.title,
                                                                )}
                                                              </li>
                                                            ),
                                                          )}
                                                        </ul>
                                                      )}

                                                    {item.offering_ids.length >
                                                      0 &&
                                                    Object.entries(
                                                      item.offering_ids,
                                                    ).length > 0
                                                      ? item.offering_ids.map(
                                                          (
                                                            item,
                                                            indexOffering,
                                                          ) => (
                                                            <div
                                                              className="d-flex align-items-center phases_item mt-3"
                                                              key={
                                                                indexOffering
                                                              }>
                                                              <div
                                                                className="customer_name flex-1"
                                                                style={{
                                                                  backgroundColor:
                                                                    'white',
                                                                }}>
                                                                <div className="alias_name">
                                                                  {capitalizeFirst(
                                                                    item.title,
                                                                  )}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                  <div
                                                                    style={{
                                                                      cursor: editProject ? 'pointer' : 'default',
                                                                      color:
                                                                        '#5ABD42',
                                                                      marginRight:
                                                                        '10px',
                                                                    }}
                                                                    onClick={() => {
                                                                      editProject &&
                                                                        setShowDateModal(
                                                                          true,
                                                                        );

                                                                        const formattedDuration = checkDateFormat(item.duration)


                                                                      setProjectDetail(
                                                                        prev => ({
                                                                          ...prev,
                                                                          date: formattedDuration,
                                                                          index_Offering:
                                                                            indexOffering,
                                                                          index_Deliverable:
                                                                            index,
                                                                          index_Milestone:
                                                                            mile_index,
                                                                        }),
                                                                      );
                                                                    }}> 
                                                                    {
                                                                      (item.duration &&
                                                                        item.duration !==
                                                                          '')
                                                                        ? checkDateFormat(
                                                                              item.duration,
                                                                          ) !==
                                                                          '0'
                                                                          ? getTimeDifference(
                                                                                item.duration,
                                                                            )
                                                                          : 'Select date'
                                                                        : 'Select date'}
                                                                    
                                                                  </div>
                                                                  {editProject && (
                                                                    <span className="as-link cross-icon-field">
                                                                      <i
                                                                        className="fas fa-times text-secondary"
                                                                        onClick={() => {
                                                                          setDeleteData(
                                                                            {
                                                                              milestone_index:
                                                                                mile_index,
                                                                              deliverable_index:
                                                                                index,
                                                                              type: 'offering',
                                                                              Offering_index:
                                                                                indexOffering,
                                                                              model: true,
                                                                            },
                                                                          );
                                                                        }}
                                                                        style={{
                                                                          fontSize:
                                                                            '1.5rem',
                                                                        }}></i>
                                                                    </span>
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ),
                                                        )
                                                      : !editProject && (
                                                        <div className="praise-list-item no-border pt-0">
                                                          <p className="text-center">
                                                            No offerings
                                                            available
                                                          </p>
                                                          </div>
                                                        )}
                                                  </div>
                                                </div>
                                              )}
                                            {tabType === 'tasks' &&
                                              item?.task_ids && (
                                                <div>
                                                  <div className="">
                                                    {searchOfferingTask &&
                                                      taskList?.length > 0 && (
                                                        <ul className="custom-suggestions invoices_suggestion">
                                                          {taskList.map(
                                                            suggestion => (
                                                              <li
                                                                className="as-link"
                                                                key={
                                                                  'suggestion' +
                                                                  suggestion.id
                                                                }
                                                                onClick={() => {
                                                                  addTaskOrOffering(
                                                                    mile_index,
                                                                    index,
                                                                    'task',
                                                                    suggestion,
                                                                  );
                                                                }}>
                                                                {capitalizeFirst(
                                                                  suggestion.title,
                                                                )}
                                                              </li>
                                                            ),
                                                          )}
                                                        </ul>
                                                      )}

                                                    {Object.entries(
                                                      item?.task_ids,
                                                    )?.length > 0
                                                      ? item.task_ids.map(
                                                          (item, indexTask) => {
                                                            return (
                                                              <div
                                                                className="d-flex align-items-center mt-3"
                                                                key={indexTask}>
                                                                <div
                                                                  className="customer_name flex-1"
                                                                  style={{
                                                                    backgroundColor:
                                                                      'white',
                                                                  }}>
                                                                  <div className="alias_name">
                                                                    {capitalizeFirst(
                                                                      item.title,
                                                                    )}
                                                                  </div>
                                                                  <div className="d-flex align-items-center">
                                                                    <div
                                                                      style={{
                                                                        cursor: editProject ? 'pointer' : 'default',
                                                                        color:
                                                                          '#5ABD42',
                                                                        marginRight:
                                                                          '10px',
                                                                      }}
                                                                      onClick={() => {
                                                                        editProject &&
                                                                          setShowDateModal(
                                                                            true,
                                                                          );

                                                                        setProjectDetail(
                                                                          prev => ({
                                                                            ...prev,
                                                                            date:
                                                                              item.due_date &&
                                                                              item.due_date !==
                                                                                '0000-00-00 00:00:00'
                                                                                ? item.due_date
                                                                                : '',

                                                                            index_Task:
                                                                              indexTask,
                                                                            index_Deliverable:
                                                                              index,
                                                                            index_Milestone:
                                                                              mile_index,
                                                                          }),
                                                                        );
                                                                      }}>
                                                                      {
                                                                         item.due_date &&
                                                                         item.due_date !==
                                                                           '0000-00-00 00:00:00'
                                                                           ? getTimeDifference(item.due_date)
                                                                           : 'Select date'
                                                                           }
                                                                    </div>
                                                                    {editProject && (
                                                                      <span className="as-link cross-icon-field">
                                                                        <i
                                                                          className="fas fa-times text-secondary"
                                                                          onClick={() => {
                                                                            setDeleteData(
                                                                              {
                                                                                milestone_index:
                                                                                  mile_index,
                                                                                deliverable_index:
                                                                                  index,
                                                                                type: 'task',
                                                                                Task_index:
                                                                                  indexTask,
                                                                                model: true,
                                                                              },
                                                                            );
                                                                          }}
                                                                          style={{
                                                                            fontSize:
                                                                              '1.5rem',
                                                                          }}></i>
                                                                      </span>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            );
                                                          },
                                                        )
                                                      : !editProject && (
                                                        <div className="praise-list-item no-border pt-0">
                                                          <p className="text-center">
                                                            No tasks available
                                                          </p>
                                                          </div>
                                                        )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                },
                              )
                            ) : (
                              !editProject && (
                                <div className="praise-list-item no-border pt-0">
                                <p className="text-center">
                                  No {dropdownId} available
                                </p>
                                </div>
                              )
                            )
                          ) : (
                            <div className="praise-list-item no-border pt-0">
                            <p className="text-center">
                              No {dropdownId} available
                            </p>
                            </div>
                          )}
                        </>
                      ) : (
                        dropdownId === 'Overview' && (
                          <>
                            <div className="fieldset">
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlId="validationCustom01">
                                  Project Name
                                </Form.Label>
                              </div>

                              <div className="d-flex align-items-center phases_item mt-0">
                                <div className="customer_name flex-1">
                                  <div className="deliverables_list mt-0">
                                    <div className="deliverable_item">
                                      {capitalizeFirst(
                                        selectedProjectData.name,
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="fieldset">
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlId="validationCustom01">
                                  Customer Name
                                </Form.Label>
                              </div>

                              {selectedProjectData?.customer_details?.map(
                                (item, index) => (
                                  <div className="d-flex align-items-center phases_item mt-0">
                                    <div className="customer_name flex-1">
                                      <div className="deliverables_list mt-0">
                                        <div className="deliverable_item">
                                          <div
                                            key={index}
                                            className="deliverable_item">
                                            {capitalizeFirst(
                                              item.first_name +
                                                ' ' +
                                                item.last_name,
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>

                            <div className="fieldset">
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlId="validationCustom01">
                                  POC Name
                                </Form.Label>
                              </div>

                              {selectedProjectData?.poc_details?.map(
                                (item, index) => (
                                  <div className="d-flex align-items-center phases_item mt-0">
                                    <div className="customer_name flex-1">
                                      <div className="deliverables_list mt-0">
                                        <div className="deliverable_item">
                                          <div
                                            key={index}
                                            className="deliverable_item">
                                            {capitalizeFirst(
                                              item.first_name +
                                                ' ' +
                                                item.last_name,
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>

                            <div className="fieldset">
                              <div className="d-flex justify-content-between input-plus-icon-holder">
                                <Form.Label controlId="validationCustom01">
                                  Teams
                                </Form.Label>
                              </div>

                              {selectedProjectData?.team_alias_detail?.map(
                                (item, index) => (
                                  <div className="d-flex align-items-center phases_item mt-0">
                                    <div className="customer_name flex-1">
                                      <div className="deliverables_list mt-0">
                                        <div className="deliverable_item">
                                          <div
                                            key={index}
                                            className="deliverable_item">
                                            {capitalizeFirst(
                                              item.role_alias
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ),
                              )}
                            </div>
                          </>
                        )
                      )}

                      <Button
                        className="btn-dark"
                        type="submit"
                        style={{display: 'none'}}
                        ref={buttonRef}></Button>
                    </div>
                  </Form>
                </div>
              </Scrollbars>
            </div>
          </div>

          {editProject && (
            <div className="filter-btn-wrapper fix-width-forms mt-2">
              <div
                className="invite-btn text-center fix-width-forms bg_transparent pb-2 pt-2"
                style={{bottom: 0}}>
                <Button
                  className="btn-dark mb-0"
                  type="submit"
                  onClick={() => {
                    handleClick();
                  }}>
                  {/* {props.editAction ? 'Update Task' : } */}
                  {'Update Project'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </SlidingPanel>

      {deleteData.model && (
        <ConfirmationModel
          closeModel={() => {
            setDeleteData({
              model: false,
            });
          }}
          discription={`Are you sure you want to delete this
            ${deleteData.type} `}
          buttonOne={'Yes'}
          buttonTwo={'No Thanks'}
          ButtonOneAction={() => {
            removeName();
          }}
        />
      )}
      {showDateModal && (
        <CustomModal
          size="medium"
          onClose={() => {
            setShowDateModal(false);
          }}>
          <div className="filter-wrapper">
            <div className="filter-category-list">
              <ul style={{listStyleType: 'none', padding: 0}}>
                <li style={{marginBottom: '5px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{paddingRight: '15px'}}>
                      <Form.Label>Date</Form.Label>
                    </div>
                    {/* <Form.Control
                      value={projectDetail.date}
                      min={moment(today).format('YYYY-MM-DD')}
                      type="date"
                      placeholder="Select date"
                      onChange={e => {
                        setProjectDetail({
                          ...projectDetail,
                          date: e.target.value,
                        });
                      }}
                    /> */}

            <Calender 
              minDate={moment(today).format('YYYY-MM-DD')}
              maxDate={getMaxDateFromToday}
              setSelectedDate={e => {
                if(e){
                setProjectDetail({
                  ...projectDetail,
                  date: e,
                });
              }else{
                setProjectDetail({
                  ...projectDetail,
                  date: null,
                });
              }
              }}
              selectedDate={projectDetail.date ? new Date(projectDetail.date) : null}
              showDate={true} 
              showTime={false}
              />

                  </div>
                </li>
              </ul>
              <div className="filter-btn-wrapper text-center">
                <Button
                  className="btn-dark"
                  onClick={() => {
                    handleDates();

                    // (dropdownId === 'Tasks' &&
                    // updateTaskById(mile_index, projectDetail.index_Task ))
                    setShowDateModal(false);
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </CustomModal>
      )}

      {showComponent === 'membershipLevel' && (
        <CustomModal
          size="small"
          onClose={() => {
            setMembershipList([]);
            setShowComponent('');
          }}
          className="availability-modal">
          <div
            className="ticket-dates-wrapper  meetup_date_repeat"
            style={{width: '-webkit-fill-available'}}>
            {membershipList.length > 0 &&
              membershipList.map((item, index) => (
                <MembershipDealItem
                  item={item}
                  ItemIndex={index}
                  // onSelectionChange={(index, levelId, durationId)=>{
                  //   handleMembershipChange(index, levelId, durationId)
                  // }}
                  setMembershipList={setMembershipList}
                  membershipList={membershipList}
                />
              ))}

            <div className="invite-btns  p-0 pt-3 text-center">
              <Button
                className="px-5"
                onClick={() => {
                  handleSaveMemberShip();
                }}>
                Select
              </Button>
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default EditProject;
