import React, {useState, useEffect} from 'react';
import {format, addMonths, subMonths,  isBefore, isAfter} from 'date-fns';
import moment from 'moment';
import { formatDates } from '../../Utils/commonUtils';
import { errorMessage } from '../../Utils/ToastMessages';

const Calender = ({setIsOpenCalendar, isOpenCalendar, selectMultipleDates, setSelectMultipleDates, checkTime, serverTimeS, setSelectedDate, selectedDate, type, setStartDaysTime, startDaysTime, closeDaysTime, setCloseDaysTime, showDate = true, showTime = true, onConfirm, minDate, maxDate}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(selectedDate ? new Date(selectedDate) : new Date());
  // const [selectedHour, setSelectedHour] = useState(startDaysTime ? moment(startDaysTime).format('h') : closeDaysTime ? moment(closeDaysTime).format('h') : selectedDate ? moment(selectedDate).format('h') : 12);
  
  const [selectedHour, setSelectedHour] = useState(() => {
    if (!setCloseDaysTime && startDaysTime) return Number(moment(startDaysTime).format('h'));
    if (setCloseDaysTime && closeDaysTime) return Number(moment(closeDaysTime).format('h'));
    if ( type === 'invoice' && selectedDate) return Number(moment(selectedDate).format('h'));
    return 12;  // Default to 12 if none of the above are set
  });
  
  const [selectedMinute, setSelectedMinute] = useState(() => {
      if (!setCloseDaysTime && startDaysTime) return Number(moment(startDaysTime).format('m'));
      if (setCloseDaysTime && closeDaysTime) return Number(moment(closeDaysTime).format('m'));
      if ( type === 'invoice' && selectedDate) return Number(moment(selectedDate).format('m'));
      return 0;  // Default to 12 if none of the above are set
    }
  );
  const [amPm, setAmPm] = useState('AM');
  const [view, setView] = useState('date'); // 'date' or 'time'
  // const [selectMultipleDates, setSelectMultipleDates] = useState([]); // 'date' or 'time'

  console.log(currentDate, 'currentDatecurrentDate')
  console.log(selectedDate, 'selectedDateselectedDate')
  console.log(selectMultipleDates, 'selectMultipleDatesselectMultipleDates')
  console.log(minDate, 'minDateminDate')
  console.log(maxDate, 'maxDatemaxDate')
  console.log(serverTimeS, 'serverTimeSserverTimeS')
  console.log(selectedHour, '===>selectedHour')

  const multipleDatesHandle = (datesArray) => {
    if (!datesArray.length) {
      return "Error: Array is empty.";
    }
  
    const uniqueDates = [...new Set(datesArray)];
  
    // const sortedDates = uniqueDates.sort((a, b) => new Date(a) - new Date(b));
  
    setSelectMultipleDates(uniqueDates); // Ensure you update the state with sorted dates
  };


  useEffect(() => {
    if(showDate){
    const formatDate = formatDates(minDate, maxDate)

    if (isBefore(currentDate, formatDate.formattedMinDate)) {
      setCurrentDate(minDate); // Adjust currentDate to minDate if it's before minDate
    }
    if (isAfter(currentDate, formatDate.formattedMaxDate)) {
      setCurrentDate(maxDate); // Adjust currentDate to maxDate if it's after maxDate
    }
  }
  }, [minDate, maxDate]);


  const selectDate = (day) => {
  
    if (showDate && showTime){
      const selectTime = moment(
        `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
        "hh:mm A"
      ).format("HH:mm"); 
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);

      const datePart = moment(newDate).format("YYYY-MM-DD");
      const combinedDateTime = moment(`${datePart} ${selectTime}`, "YYYY-MM-DD HH:mm");

      setSelectedDate(combinedDateTime); 

    }else if(showDate && !showTime){  
      const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Add leading zero for month
      const daysss = String(newDate.getDate()).padStart(2, '0'); // Add leading zero for day
      const formattedDate = `${year}-${month}-${daysss}`; // Format as YYYY-MM-DD
      setSelectedDate(formattedDate);
      setIsOpen(!isOpen);

    }
  
  }
  
  
  useEffect(() => {
    if (showDate) {
      setView('date');
    } else {
      setView('time');
    }
  }, []);

  const clearSelection = () => {
    if(showDate){
    setCurrentDate(new Date()); // Reset to today's date
    if(setSelectedDate){
    setSelectedDate(null)
    }
    if(setSelectMultipleDates){
    setSelectMultipleDates([])
    }
    }else if(showTime){
    if(setStartDaysTime){
   
    setStartDaysTime('')
    }else if(setCloseDaysTime){
   
      setCloseDaysTime('')
    }
  }
    setIsOpen(false);
  };


// Month Navigation with range checking

  const prevMonth = () => {
    const newDate = subMonths(currentDate, 1);
      setCurrentDate(newDate);
  };

  const nextMonth = () => {
    const newDate = addMonths(currentDate, 1);
      setCurrentDate(newDate);

  };

  const handleHourChange = hour => {
    setSelectedHour(hour);
  };

  const handleMinuteChange = minute => {
    setSelectedMinute(minute);
  };

  const toggleAmPm = () => {
    setAmPm(prev => (prev === 'AM' ? 'PM' : 'AM'));
  };

  console.log(startDaysTime, 'startDaysTimeddddddddddddddddstartDaysTimestartDaysTime')

  // New function for handling OK button
  const handleOkClick = async () => {
    if(showTime){
    if (type === 'singleTime') {
      const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
  
      if (!checkTime) {
        const serverDate = moment(serverTimeS).format('YYYY-MM-DD');
        const selectedDateFormatted = moment(selectedDate).format('YYYY-MM-DD');
  
        const serverTimeMoment = moment(serverTimeS, 'YYYY-MM-DD HH:mm A');
        const selectedTimeMoment = moment(
          `${selectedDateFormatted} ${selectedHour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
          'YYYY-MM-DD HH:mm A'
        );
        const isSameDate = serverDate === selectedDateFormatted;
        const isBeforeServerTime = selectedTimeMoment.isBefore(serverTimeMoment);

        const selectedTimesss = moment(
          `${selectedHour.toString().padStart(2, "0")}:${selectedMinute.toString().padStart(2, "0")}`,
          'HH:mm A'
        );
        
        const closeDaysTimeMoment = moment(closeDaysTime, 'HH:mm A');
        const isValidTime = closeDaysTimeMoment.isBefore(selectedTimesss) || closeDaysTimeMoment.isSame(selectedTimesss);
  
        if (serverTimeS && isSameDate && isBeforeServerTime) {

          errorMessage('Choose correct time');
        } else {
          if(closeDaysTime && isValidTime){
            errorMessage('Choose correct time');
          }else{
          await setStartDaysTime(selectTime); // Await state update
          setIsOpen(false); // Close modal after state is set
          }
        }
      } else if (checkTime) {
     
  
        const timeStartMoment = moment(startDaysTime, 'hh:mm A');
        const timeEndMoment = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A");
  
        const matchTime = timeStartMoment.isSame(timeEndMoment) || timeStartMoment.isAfter(timeEndMoment);
  
        if (matchTime === false) {
          await setCloseDaysTime(selectTime); // Await state update
          setIsOpen(false); // Close modal after state is set
        } else {
          errorMessage('Choose correct time');
        }
      } else {
        await setStartDaysTime(selectTime); // Await state update
        setIsOpen(false); // Close modal after state is set
      }
    }else if(showTime && showDate){
      
      const selectTime = moment(
        `${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`,
        "hh:mm A"
      ).format("HH:mm"); 
    
      const datePart = moment(selectedDate).format("YYYY-MM-DD");
      const combinedDateTime = moment(`${datePart} ${selectTime}`, "YYYY-MM-DD HH:mm");

      setSelectedDate(combinedDateTime); 
    }else{
      if(type === 'multipleTimes'){
       const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
       await setStartDaysTime(selectTime); // Await state update
       setIsOpen(false);
     }else if(type === 'time'){
      const selectTime = moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A").format("hh:mm A");
      setStartDaysTime(selectTime)
       setIsOpen(false);
     }
   }
  }else{
    if(isOpenCalendar){
      setIsOpenCalendar(false)
    }else{
      setIsOpen(false);
    }
  }
  };
  

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const daysInMonth = Array.from(
    {
      length: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0,
      ).getDate(),
    },
    (_, i) => i + 1,
  );


  const hours = Array.from({length: 12}, (_, i) => i + 1);
  const minutes = Array.from({length: 60}, (_, i) => i);
  
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getDay();

  console.log(moment(`${selectedHour}:${selectedMinute.toString().padStart(2, "0")} ${amPm}`, "hh:mm A"),"================>", startDaysTime)
  console.log(closeDaysTime, 'closeDaysTimecloseDaysTime')

  return (
    <div className="calendar-custom-relative-holder">
      

      <div className="relative">
        <button 
        // ref={calendarRef}
        type='button'
        style={{maxWidth: type === 'multiple' && '100%', display: isOpenCalendar && 'none'}}
          onClick={()=>{
            setIsOpen(!isOpen)
          }}
          className="flex items-center justify-between w-full max-w-xs bg-white transition duration-300 ease-in-out">
          <span className="clnd-date-time-txt">
            {showDate && type !== 'multiple' && (selectedDate ? format(selectedDate, 'MM/dd/yyyy') : 'Select date')}
            {showTime && type === 'time' && ((startDaysTime && startDaysTime !== '' )? moment(startDaysTime).format('hh:mm A') : 'Select time')}
            {showTime && type === 'singleTime' && (
          setCloseDaysTime 
            ? (closeDaysTime ? closeDaysTime.format("HH:mm A") : 'Select time') 
            : (startDaysTime ? startDaysTime.format("HH:mm A") : 'Select time') 
        )}

            {showTime && type === 'multipleTimes' && (startDaysTime ? startDaysTime : 'Select time')
            }
          {showDate && type === 'multiple' && selectMultipleDates.length > 0 ? (
            selectMultipleDates.length === 1 
              ? selectMultipleDates[0] 
              : selectMultipleDates.join(', ')
          ) : showDate && type === 'multiple' && (
            "Select unavailable dates"
          )
        }

            
          </span>
          <svg
            className={`w-5 h-5 transition-transform duration-300 ease-in-out ${
              isOpen ? 'rotate-0' : ''
            }`}
            fill="none"
            stroke="#000000"
            viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-7 8h6m-6 4h6m-8 6h10a2 2 0 002-2V7a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </button>
        

        <div
          className={`fixed top-0 left-0 bg-white rounded-lg shadow-lg z-10 transform transition-transform duration-300 ease-in-out tail-calnd-dropdown ${
            (isOpenCalendar) || 
            isOpen
              ? 'translate-y-0 opacity-100'
              : '-translate-y-full opacity-0 pointer-events-none'
          }`} style={{ zIndex: 999 }}>
          {/* Header */}

          {/* {(showDate || showTime) && type !== 'multiple' && */}
          {((type !== 'singleTime' && type !== 'multiple' && (showTime || showDate)) || (type === 'singleTime' && selectedDate)) &&

          <div className="text-white rounded-t-lg p-2 date-time-pannel-head">
            <div className="text-center text-sm uppercase">
              {/* Select {showDate && 'Date'}
              {showTime && showDate && ' and '}
              {showTime && 'Time'} */}
            </div>
            <div className="text-center font-semibold">
              {showDate && selectedDate && format(selectedDate, 'MM/dd/yyyy')}
              {showTime &&
                ` ${selectedHour}:${selectedMinute
                  .toString()
                  .padStart(2, '0')} ${amPm}`}
            </div>
          </div>
}

          {showDate && showTime && (
            <div className="flex justify-center items-center p-2 bg-white border-b date-time-combn-section">
              <button
                onClick={() => setView('date')}
                className={`p-2 w-1/2 text-center font-semibold ${
                  view === 'date'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`}>
                Date
              </button>
              <button
                onClick={() => setView('time')}
                className={`p-2 w-1/2 text-center font-semibold time-btn ${
                  view === 'time'
                    ? 'roi-bg-green font-semibold'
                    : 'hover:roi-bg-green'
                }`} >
                Time
              </button>
            </div>
          )}

          {view === 'date' && showDate && (
            <>
              {/* Calendar Controls */}
              <div className="flex justify-between items-center bg-white p-2 border-b full-date-section">
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={prevMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </button>
                <div className="text-lg font-medium exact-date-holder">
                {currentDate && format(currentDate, 'MMMM yyyy')}        
                </div>
                <button
                  className="p-1 rounded-full hover:bg-gray-100 transition duration-300 ease-in-out"
                  onClick={nextMonth}>
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="#9b9787"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>

              {/* Days of the Week */}
              <div className="grid grid-cols-7 text-center text-gray-500 bg-white py-2 day-name-section">
  {daysOfWeek.map(day => (
    <div key={day} className="text-sm font-medium day-name-color">
      {day}
    </div>
  ))}
</div>

{/* Calendar Days */}
<div className="grid grid-cols-7 text-center bg-white py-0">
  {/* Add empty days to offset the first day of the month */}
  {Array.from({ length: firstDayOfMonth }).map((_, index) => (
    <div key={`empty-${index}`} className="py-2"></div>
  ))}

  {/* Render days of the month */}
  {daysInMonth.map(day => {
 const currentDayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
 const formatDate = formatDates(minDate, maxDate);
 const isDisabled = currentDayDate < formatDate.formattedMinDate || currentDayDate > formatDate.formattedMaxDate;
 const isSelectedDay = selectedDate && selectedDate.getDate() === day;
 const isUnavailable = selectMultipleDates && selectMultipleDates.length > 0 && selectMultipleDates.includes(format(currentDayDate, 'yyyy-MM-dd'));
 const todayDate = currentDate.getDate()

console.log(formatDate, '==>formatDate')
console.log(isDisabled, '==>isDisabled')

 const classNames = [
  "round-custom-time transition duration-300 ease-in-out",
  isSelectedDay && !isDisabled ? "roi-bg-green text-white cursor-pointer" : "",
  todayDate === day && !selectedDate ? "roi-bg-green text-white cursor-pointer" : "",
  isUnavailable ? "roi-bg-green text-white cursor-pointer" : "",
  isDisabled ? "text-gray-400 cursor-not-allowed" : "hover:roi-bg-green cursor-pointer"
].filter(Boolean).join(" ");


console.log(currentDayDate, 'currentDayDatecurrentDayDate')
console.log(formatDate, 'formatDateformatDate')
console.log(isSelectedDay, 'isSelectedDayisSelectedDay')

    return (
      // {type !== 'disable' && selectedDate !== '' ?
      <div
        key={day}
        className={classNames}
        style={{backgroundColor: (type === 'multiple' && isUnavailable) && '#429734'}}
        onClick={() => {
          if (!isDisabled) {
            if (type !== 'multiple') {
              selectDate(day);
            } else {
              const formattedDate = format(currentDayDate, 'yyyy-MM-dd'); // Updated format
              if (isUnavailable) {
                setSelectMultipleDates(prev => prev.filter(date => date !== formattedDate));
              } else {
                multipleDatesHandle([...selectMultipleDates, formattedDate]);
              }
            }
          }
        }}
      >
        {day}
      </div>
    );
})}
</div>

            </>
          )}

          {/* {view === 'time' && showTime && ( */}
          {view === 'time' && ((type !== 'singleTime' && showTime) || (type === 'singleTime' && selectedDate)) && (

            <div className="flex justify-center items-center p-4 border-t time-corner-date-time-calander">
              {/* Hour Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Hour</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto overflow-x-hidden snap-y"
                  style={{scrollBehavior: 'smooth'}}>
                  {hours.map(hour => (
                    <div
                      key={hour}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out snap-start ${
                          selectedHour === hour 
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}  
                      onClick={() => {
                      handleHourChange(hour)
                      }
                      }>
                      {hour}
                    </div>
                  ))}
                </div>
              </div>

              {/* Minute Picker */}
              <div className="flex flex-col items-center mx-4">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">Minute</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {minutes.map(minute => (
                    <div
                      key={minute}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        selectedMinute === minute 
                        ? 'roi-bg-green text-white'
                        : ''
                      }`}
                      onClick={() => {
                      handleMinuteChange(minute)
                      }
                    }>
                      {minute.toString().padStart(2, '0')}
                    </div>
                  ))}
                </div>
              </div>

              {/* AM/PM Picker */}
              <div className="flex flex-col items-center">
                <div className="text-sm text-gray-600 mb-2 font-medium clnd-hour-txt">AM/PM</div>
                <div
                  className="px-2 py-2 h-24 w-14 bg-gray-50 rounded-md overflow-y-auto snap-y overflow-x-hidden"
                  style={{scrollBehavior: 'smooth'}}>
                  {['AM', 'PM'].map(period => (
                    <div
                      key={period}
                      className={`round-custom-time cursor-pointer p-2 text-center transition duration-300 ease-in-out rounded-full snap-start ${
                        amPm === period
                          ? 'roi-bg-green text-white'
                          : ''
                      }`}
                      onClick={() => {
                        // handleTime('period', period)
                        setAmPm(period)
                      }
                      }>
                      {period}
                    </div>
                  ))}
                </div>
              </div>

  
            </div>
            
          )}
        
        {/* {(showTime || showDate) && */}
        {((type !== 'singleTime' && (showTime || showDate)) || (type === 'singleTime' && selectedDate)) &&

          <div className="flex justify-between p-1 bg-gray-50 rounded-b-lg ok-clear-btn-section">
           {type !== 'appointment' &&
            <button
            type='button'
              onClick={clearSelection}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Clear
            </button>
}
            <button
            type='button'
              onClick={handleOkClick}
              className="px-4 py-2 text-sm text-gray-600 rounded-lg transition duration-300 ease-in-out date-custom-clear-btn">
              Okay
            </button>
          </div>
}
 
 
        </div>
      </div>
    </div>
  );
};

export default Calender;
